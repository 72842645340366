<template>
    <page-information-component
        :paragraphOne="text_one"
        :paragraphTwo="text_two"
    ></page-information-component>
</template>

<script>
import PageInformationComponent from '@/components/UI/PageInformationComponent.vue'

export default {
    name: 'NotFound',
    components: { PageInformationComponent },
    data: () => ({
        text_one: 'El servidor se encuentra ',
        text_two: 'mantenimiento.',
    })
}
</script>