<template>
    <!-- nvl 2 -->
    <intranet-nav-content>
        <template slot="links">
            <div class="intranet-nav-content apk-nav-tabs">
                <intranet-nav-content-link-exact :link="{ name: 'Dashboard', path: { name: 'employee-postulants-dashboard' } }"/>
                <intranet-nav-content-link :link="{ name: 'Asesor de Ventas', path: { name: 'employee-postulants-list-SA' } }" v-show="hasSalesAdvisorPermission"/>
                <intranet-nav-content-link :link="{ name: 'Asesor Cultural', path: { name: 'employee-postulants-list-CA' } }" v-show="hasCulturalAdvisorPermission"/>
            </div>
        </template>
    </intranet-nav-content>
</template>

<script>
import IntranetNavContent from '@/layout/NavContent/IntranetNavContent';
import IntranetNavContentLink from '@/layout/NavContent/IntranetNavContentLink';
import IntranetNavContentLinkExact from '@/layout/NavContent/IntranetNavContentLinkExact';

export default {
    name: 'EmployeePostulants',
    components: { 
        IntranetNavContent,
        IntranetNavContentLink,
        IntranetNavContentLinkExact
    },
    computed: {
        hasSalesAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-ventas')
        },
        hasCulturalAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-cultural')
        },
    }
};
</script>

<style scoped>
.intranet-nav-content {
    display: flex;
    align-items: center;
    overflow-x:auto;
    white-space:nowrap;
    margin-bottom: 1px;
}
</style>