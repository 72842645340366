import { get } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl
const headers = { Authorization: `Bearer ${store.state.user.attributes.token}` }

//get
function getProgramSeasonParticipants (pPage, pIdParticipant) {
    const URI = `${BASE_URL}/api/participants/${pIdParticipant}/programSeasonParticipants?page=${pPage}`
    return get(URI, { headers })
}

export { getProgramSeasonParticipants }