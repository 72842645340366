<template>
    <b-row>
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Entrevistas
                            </h3>
                        </b-col>

                        <b-col cols="auto">
                            <!-- <b-button variant="magenta" size="sm" @click="reloadData">
                                <b-icon icon="collection-play-fill" scale="1.2"></b-icon>
                            </b-button> -->

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.2"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- table salesAdvisor.season.programSeasonPostulants -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th></th>
                            <th>Subject</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Entrevistador</th>
                            <th>Estado</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                {{ index + 1 }}
                            </td>

                            <td class="text-right">
                                <b-btn 
                                    :to="{
                                        name: 'postulant-program-interviews-interview-details',
                                        params: { 'IdEnglishInterviewSchedule': row.id }    // IdEnglishInterviewSchedule
                                    }"
                                    variant="outline-violeta"
                                    size="sm"
                                >
                                    <b-icon icon="list-task" ></b-icon>
                                </b-btn>
                            </td>

                            <td>Entrevista de Inglés</td>
                            <td>{{ row.attributes.start_date }}</td>
                            <td>{{ row.attributes.start_time }} a {{ row.attributes.end_time }}</td>

                            <td>
                                 <div v-show="row.attributes.salesAdvisor_id != null ? true : false">
                                    SA : {{ row.attributes.salesAdvisor_full_name }}
                                </div>

                                <div v-show="row.attributes.culturalAdvisor_id != null ? true : false">
                                    CA : {{ row.attributes.culturalAdvisor_full_name }}
                                </div>
                            </td>

                            <td :class="row.attributes.schedule_status == 'culminado' ?  'text-green' : 'text-yellow'">
                                {{ row.attributes.schedule_status }}
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramSeasonPostulantInterviews } from '@/api/ProgramSeasonPostulant/EnglishInterviewSchedules';

export default {
    name: "InterviewsList",
    data: () => ({
        tableData: [],
        loadingTableData: false,
    }),

    created() {
        this.getPostulantInterviewsData()
    },

    methods: {
        getPostulantInterviewsData () {
            this.loadingTableData = true
            //
            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant    // ok

            getProgramSeasonPostulantInterviews(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch( err => {
                    console.log('getPostulantInterviewsData Error:', err.response)
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },
        reloadData () {
            this.getPostulantInterviewsData()
        }
    }
}
</script>