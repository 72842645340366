<template>
    <general-process-component></general-process-component>
</template>

<script>
import GeneralProcessComponent from '@/components/Process/GeneralProcessComponent.vue'

export default {
    name: 'GeneralProcess',
    components: { GeneralProcessComponent },
}
</script>