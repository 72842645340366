<template>
    <b-modal
        id="modal-contract-payments"
        centered
        hide-footer
        size="md"
        header-class="pb-0"
    >
        <!-- no-close-on-backdrop
        no-close-on-esc
    > -->
        <!-- modal header -->
        <template #modal-header class="pb-0">
            <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                <b-row align-h="between" align-v="center">
                    <b-col cols="8"><h3 class="text-primary mb-0 text-button">PAGOS PENDIENTES</h3></b-col>
                    <b-col cols="auto" class="text-right">
                        <b-button size="sm" variant="magenta" @click="modalContractPaymentClose" v-show="!contractPaymentLoading">
                            <b-icon icon="x" scale="1.5"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>

        <!-- modal content -->
        <template #default>
            <b-container>
                <b-row style="overflow-x:auto">
                    <b-col v-if="contractPaymentLoading">
                        <div style="height:300px;">
                            <pulse-loader
                                :color="'#5e72e4'"
                                :margin="'10px'"
                                :size="15"
                                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                        </div>
                    </b-col>

                    <b-col cols="12" v-else>
                        <div class="popup">
                            <table class="popup__table">
                                <thead class="popup__table__head">
                                    <tr>
                                        <th class="popup__table__head__title">CONCEPTO</th>
                                        <th class="popup__table__head__title">MONTO</th>
                                        <th class="popup__table__head__title popup__table__head__title--text-right">FECHA MAX</th>
                                    </tr>
                                </thead>

                                <tbody class="popup__table__body">
                                    <tr v-for="(contractPayment, i) in contractPayments" :key="`${i}-cp`" class="popup__table__row">
                                        <td class="popup__table__body__data">{{ contractPayment.attributes.payment_concept  }}</td>
                                        <td class="popup__table__body__data">{{ contractPayment.attributes.amount_format }}</td>
                                        <td class="popup__table__body__data popup__table__body__data--text-right">{{ contractPayment.attributes.expiration_at_formated }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>

                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import { getPostulantContractPayments } from '@/api/Postulant/PostulantContractPayment'

export default {
    name: 'popup',

    data: () => ({
        contractPayments: {},
        contractPaymentLoading: false
    }),

    mounted () {
        this.modalContractPaymentClick()
    },

    computed: {
        userPostulantData () {
            return this.$store.getters['user/getUserRoleData']('postulante')
        }
    },

    methods: {
        modalContractPaymentClick () {
            this.contractPaymentLoading = true

            getPostulantContractPayments (this.userPostulantData.attributes.idToManage)
                .then(({data}) => {
                    // console.log('contract payments', data);
                    if (data.data.length > 0) {
                        this.contractPayments = data.data.filter(el => el.attributes.status == 'rojo')
                        if (this.contractPayments.length != 0) this.$bvModal.show('modal-contract-payments')
                    }

                })
                .finally(() => {
                    this.contractPaymentLoading = false
                })
        },

        modalContractPaymentClose () {
            this.$bvModal.hide('modal-contract-payments')
        },
    }
}
</script>

<style scoped>
.popup {
    width: 100%;
}
.popup__table {
    width: 100%;
    border-collapse: collapse;
}
.popup__table__row:hover {
    cursor: pointer;
    background-color: rgba(94, 114, 228, 0.25);
}

.popup__table__head__title {
    color:#5e72e4;
    padding-bottom: 10px;
}
.popup__table__head__title {
    padding-bottom: 8px;
}

.popup__table__head__title--text-right,
.popup__table__body__data--text-right {
    text-align: right;
}


@media (max-width: 500px) {
    .popup__table__head {
        font-size: 12px;
    }
    .popup__table__body {
        font-size: 11px;
    }
}

.modal-user-data {
    border: 1px solid #5e72e4;
    border-radius:10px;
    padding: 10px 0px;
}
/* .popup-content{
    min-width: 500px;
} */
</style>