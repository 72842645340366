<template>
    <program-sponsors-list routeName="employee-wat-sponsor-programation"></program-sponsors-list>
</template>

<script>
import ProgramSponsorsList from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorsList.vue'

export default {
    name: "WatSponsorList",
    components: {ProgramSponsorsList  },
    data: () => ({})
}
</script>