<template>
    <all-participant-list-component></all-participant-list-component>
</template>

<script>
import AllParticipantListComponent from '../../../../components/Employee/Participant/AllParticipantListComponent.vue'
export default {
  components: { AllParticipantListComponent },

}
</script>