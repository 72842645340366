<template>
    <b-row align-h="center">
        <!-- PAYMENTS -->
        <b-col cols="12" class="mb-4">
            <payments
                :reload="reloadPaymentsTable"
                @voucherCreated="reloadVouchersTable += 1"
            />
        </b-col>

        <!-- VOUCHERS -->
        <b-col cols="12">
            <vouchers
            :reload="reloadVouchersTable"
                @voucherUpdated="reloadPaymentsTable += 1"
            />
        </b-col>
    </b-row>
</template>

<script>
import Payments from './PaymentsVouchersComponents/Payments.vue'
import Vouchers from './PaymentsVouchersComponents/Vouchers.vue'

export default {
    name: 'ParticipantPaymentsVouchers',
    components: {
        Payments,
        Vouchers
    },
    data: () => ({
        reloadPaymentsTable: 0,
        reloadVouchersTable: 0
    })
}
</script>