<template>
    <b-row>
        <b-col>
            <b-card>
                <!-- Dashboard de Postulantes XD -->
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'PostulantsDashboard'
}
</script>