<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Documentos
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableDataSorted"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>estado</th>
                            <th><div>documento</div>presentado</th>
                            <th>Observaciones</th>
                            <th>grupo</th>
                            <th>nombre</th>
                            <th><div>documento</div>de ejemplo</th>
                            <th>descripción</th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <!-- status -->
                            <td style="width:120px">
                                <traffic-light :status="row.attributes.status"/>
                            </td>
                            <!-- file - add - edit -->
                            <td class="text-center">
                                <b-button
                                    :variant="row.attributes.file ? 'outline-primary' : 'outline-light'"
                                    size="sm" :href="row.attributes.file" target="_black"
                                >
                                    <b-icon icon="file-earmark-text" scale="1.2"></b-icon>
                                </b-button>

                                <b-button size="sm" @click="modalDocumentEditClick(row.attributes)" variant="green"
                                    v-if="row.attributes.status == 'rojo'"
                                    >
                                    <b-icon icon="plus" scale="1.4" ></b-icon>
                                </b-button>
                                
                                <b-button size="sm" @click="modalDocumentEditClick(row.attributes)" variant="primary"
                                    v-else-if="row.attributes.status == 'amarillo'"
                                >
                                    <b-icon icon="pencil-square" scale="1.3" ></b-icon>
                                </b-button>
                            </td>
                            <!-- observaciones -->
                            <td>
                                <div v-if="row.attributes.participantDocumentDescription">
                                    {{ row.attributes.participantDocumentDescription }}
                                </div>
                            </td>
                            <!-- group_name -->
                            <td :class="[row.attributes.group_name == 'UNO' ? 'text-naranja' : (row.attributes.group_name == 'DOS' ? 'text-cielo' : (row.attributes.group_name == 'TRES' ? 'text-contract-payment-1' : 'text-schedule-english-2'))]" class="text-center">
                                {{ row.attributes.group_name }}
                            </td>
                            <!-- name -->
                            <td :class="row.attributes.group_name == 'UNO' ? 'text-naranja' : (row.attributes.group_name == 'DOS' ? 'text-cielo' : (row.attributes.group_name == 'TRES' ? 'text-contract-payment-1' : 'text-schedule-english-2'))">
                                {{ row.attributes.name }}
                            </td>
                            <!-- example_file -->
                            <td class="text-center">
                                <b-button
                                    :variant="row.attributes.group_name == 'UNO' ? 'outline-naranja' : (row.attributes.group_name == 'DOS' ? 'outline-cielo' : (row.attributes.group_name == 'TRES' ? 'outline-contract-payment-1' : 'outline-schedule-english-2'))"
                                    size="sm" :href="row.attributes.example_file" target="_black"
                                >
                                    <b-icon icon="file-earmark-text" scale="1.2"
                                        :variant="row.attributes.group_name == 'UNO' ? 'naranja' : (row.attributes.group_name == 'DOS' ? 'cielo' : (row.attributes.group_name == 'TRES' ? 'contract-payment-1' : 'schedule-english-2'))">
                                    </b-icon>
                                </b-button>
                            </td>
                            <!-- description -->
                            <td>
                                <div v-if="row.attributes.programDocumentDescription">
                                    {{ row.attributes.programDocumentDescription }} 
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!--
            modal document edit
        -->
        <b-modal
            id="modal-document-edit"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">
                            {{ documentSelected.status == 'rojo' ? 'AGREGAR DOCUMENTO' : 'EDITAR DOCUMENTO'}}
                            </h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalDocumentEditClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <b-col>
                            <b-card border-variant="primary" class="mb-4">
                                <div class="text-primary">
                                    {{ documentSelected.programDocumentDescription }}
                                </div>
                            </b-card>
                        </b-col>

                        <!-- resource form -->
                        <b-col cols="12">
                            <b-form @submit.prevent="editDocument">
                                <!-- file -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            :label="documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD' ? 'Link del video' : 'Archivo'"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <!-- video-url -->
                                            <b-form-input
                                                placeholder="Ingrese el link del video."
                                                v-model="link"
                                                type="url"
                                                :state="showModalInputStatus('link')"
                                                v-show="!documentEditLoading && (documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD')"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-create-resource-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>

                                            <!-- archivo -->
                                            <b-form-file
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!documentEditLoading && documentSelected.name != 'VIDEO' && documentSelected.name != 'VIDEO APLICACIÓN LIFEGUARD'"
                                                style="overflow:hidden;"
                                                :disabled="documentSelected.status == 'verde'"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-create-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Observaciones:" 
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="documentSelected.participantDocumentDescription"
                                                :state="showModalInputStatus('description')"
                                                v-show="!documentEditLoading"
                                                rows="3"
                                                max-rows="6"
                                                disabled
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto" v-show="documentSelected.status != 'verde'">
                                        <b-button variant="primary" type="submit"
                                            :disabled="documentEditLoading"
                                        >
                                            REGISTRAR
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
// programSeasonParticipant.participantDocuments
import { getProgramSeasonParticipantParticipantDocuments } from '@/api/ProgramSeasonParticipant/ParticipantDocuments';
import { registerParticipantDocument } from '@/api/ParticipantDocument/ParticipantDocument'
import TrafficLight from '@/layout/Icons/TrafficLight.vue'
import swal from 'sweetalert'

export default {
    name: "ParticipantDocuments",
    components: { TrafficLight },
    data: () => ({
        // table 
        tableData: [],
        tableDataSorted: [],
        loadingTableData: false,
        // modal edit form
        documentSelected: {},
        statusOptions: [
            { value: 'verde', text: 'verificado' },
            { value: 'amarillo', text: 'pendiente' },
            { value: 'rojo', text: 'no válido' },
        ],
        file : null,
        link : '',
        //
        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        documentEditLoading: false,
    }),
    created() {
        this.getParticipantDocumentsData()
    },
    methods: {
        getParticipantDocumentsData () {
            this.loadingTableData = true

            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant  // ok

            getProgramSeasonParticipantParticipantDocuments(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.sortTable()
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        sortTable() {
            this.tableDataSorted = [
                ...this.tableData.filter(el => el.attributes.group_name == 'UNO'),
                ...this.tableData.filter(el => el.attributes.group_name == 'DOS'),
                ...this.tableData.filter(el => el.attributes.group_name == 'TRES'),
                ...this.tableData.filter(el => el.attributes.group_name == 'CUATRO'),
            ]
        },

        modalDocumentEditClick (pDocument) {
            this.documentSelected = { ...pDocument }
            this.$bvModal.show('modal-document-edit')
        },
        modalDocumentEditClose () {
            this.$bvModal.hide('modal-document-edit')
            this.clearInputsAndStates()
        },

        // Modal Methods
        editDocument () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.documentEditLoading = true

            let IdParticipantDocument = this.documentSelected.id  // ok

            let DocumentForm = new FormData()
            DocumentForm.append('.method', 'put')
            DocumentForm.append('file', this.file)
            DocumentForm.append('link', this.link)
            DocumentForm.append('participantDocument_id', IdParticipantDocument)
            //
            DocumentForm.append('personable_type', "App\\Models\\ProgramSeasonParticipant")
            DocumentForm.append('personable_id', this.$route.params.IdProgramSeasonParticipant)

            registerParticipantDocument(DocumentForm, IdParticipantDocument)
                .then(() => {
                    swal(this.documentSelected.file ? 'Actualización correcta' : 'Creación correcta!',
                        this.documentSelected.file ? 'Documento actualizado.' : 'Documento registrado.',
                        'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.$bvModal.hide('modal-document-edit')
                                    this.clearInputsAndStates()
                                    this.getParticipantDocumentsData()
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    console.log('editDocument err: ', err.response);
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.documentEditLoading = false
                    console.log('request editDocument end');
                })
        },

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.documentSelected = {}
            this.file = null
            this.link = ''
        },
        reloadData () {
            this.getParticipantDocumentsData()
        }
    }
}
</script>