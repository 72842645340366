<template>
    <dashboard-component></dashboard-component>
</template>

<script>
import DashboardComponent from '@/components/Postulant-Participant/Dashboard/DashboardComponent.vue'

export default {
    name: 'ParticipantDashboard',
    components: { DashboardComponent },
    data: () => ({}),
};
</script>

