import { render, staticRenderFns } from "./AbandonmentsList.vue?vue&type=template&id=22c22f24&"
import script from "./AbandonmentsList.vue?vue&type=script&lang=js&"
export * from "./AbandonmentsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports