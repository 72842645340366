<template>
    <b-row>
        <b-col>
            <b-card no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Sponsors
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="openModalRegisterSponsor">
                                <b-icon icon="plus" scale="1.6"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat" scale="1.3"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>sponsor</th>
                            <th>programa</th>
                            <th>temporada</th>
                            <th>descripción</th>
                            <th>estado</th>
                            <th class="text-center">detalles</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.attributes.sponsor_name }}</td>
                            <td>{{ row.attributes.program }}</td>
                            <td>{{ row.attributes.season }}</td>
                            <td>{{ row.attributes.description }}</td>
                            <td>{{ row.attributes.status }}</td>

                            <td class="text-center">
                                <b-btn 
                                    :to="{
                                        name: `${routeName}`,
                                        params: { 'IdProgramSeasonSponsor': row.id }    //IdProgramSeasonSponsor
                                    }"
                                    variant="outline-violeta"
                                    size="sm"
                                ><b-icon icon="list-task"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <div v-show="row.attributes.programSponsorJobs == 0">
                                    <pulse-loader
                                        :loading="row.deleteLoading || false"
                                        :color="'#5e72e4'"
                                        :margin="'2px'"
                                        :size="8"
                                        class="pt-2 pb-1"
                                    ></pulse-loader>

                                    <b-btn variant="transparent" size="sm" 
                                        v-show="!row.deleteLoading && true"
                                        @click="deleteProgramSeasonSponsor(row)"
                                    ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                    </b-btn>
                                </div>
                            </td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!-- modal create-->
        <b-modal
                id="modal-create-program-season-sponsor"
                centered
                no-close-on-backdrop
                no-close-on-esc
                hide-footer
                size="md"
                header-class="pb-0"
            >
                <!-- modal header -->
                <template #modal-header>
                    <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                        <b-row align-h="between" align-v="center">
                            <b-col cols="10">
                                <h3 class="text-primary mb-0 text-uppercase">
                                    seleccionar Sponsor
                                </h3>
                            </b-col>

                            <b-col cols="2" class="text-right">
                                <b-button size="sm" variant="magenta" @click="closeModalRegisterSponsor">
                                    <b-icon icon="x" scale="1.5"/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>

                <!-- modal main content -->
                <template #default>
                    <b-container>
                        <div style="height:200px;" v-show="registerSponsorDataLoading">
                            <pulse-loader
                                :color="'#5e72e4'"
                                :margin="'10px'"
                                :size="20"
                                style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                        </div>

                        <b-list-group v-show="!registerSponsorDataLoading">
                            <b-list-group-item
                                class="d-flex justify-content-between align-items-center"
                                v-for="(sponsorJobOffer ,i) in registerSponsorData" :key="`${i}-sjo`"
                            >
                                <pulse-loader
                                    :loading="sponsorJobOffer.registerLoading || false"
                                    :color="'#5e72e4'"
                                    :margin="'5px'"
                                    :size="10"
                                    class="d-flex align-items-center"
                                ></pulse-loader>

                                <span v-show="!sponsorJobOffer.registerLoading && true">
                                    <span class="text-primary text-caption">
                                        {{ sponsorJobOffer.attributes.sponsor_name }}
                                    </span> : {{ sponsorJobOffer.attributes.program_name }}
                                </span>

                                <b-button variant="green" size="sm"
                                    @click="programSeasonSponsorRegister(sponsorJobOffer)"
                                    v-show="sponsorJobOffer.attributes.input_order != ''"
                                    class="text-caption" :disabled="sponsorJobOffer.registerLoading || false"
                                ><b-icon icon="plus" scale="1.5"></b-icon>
                                </b-button>
                            </b-list-group-item>
                        </b-list-group>
                    </b-container>
                </template>
        </b-modal>
    </b-row>
</template>

<script>
// programSeason.programSeasonSponsor
import { getProgramSeasonProgramSeasonSponsors } from '@/api/ProgramSeason/ProgramSeasonProgramSeasonSponsor';
import { destroyProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor';
import { getProgramSeason } from '@/api/ProgramSeason/ProgramSeason';
import { getSponsorJobOffersByProgram } from '@/api/SponsorJobOffer/SponsorJobOffersByProgram';
import { storeProgramSeasonSponsor } from '@/api/ProgramSeasonSponsor/ProgramSeasonSponsor'
import swal from 'sweetalert'

export default {
    name: "ProgramSponsorList",

    props: {
        routeName : {
            type: String
        }
    },

    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        programSeason: {},
        //
        registerSponsorData: {},
        registerSponsorDataLoading: false
    }),

    created() {
        this.getData()
    },

    methods: {
        getData (pPage=1) {
            this.loadingTableData = true
            let IdProgramSeason = this.$route.params.IdProgramSeason    // ok
            getProgramSeasonProgramSeasonSponsors(pPage, IdProgramSeason)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta

                    getProgramSeason(IdProgramSeason)
                        .then(({ data }) => {
                            this.programSeason = data.data.attributes
                        })
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        reloadData() {
            this.getData()
        },

        // delete program-season-sponsor
        deleteProgramSeasonSponsor (pProgramSeasonSponsor) {
            swal('Se eliminará el sponsor registrado', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramSeasonSponsor, 'deleteLoading', true)
                            destroyProgramSeasonSponsor(pProgramSeasonSponsor.attributes.id)
                                .then(() => {
                                    this.getData()
                                })
                                .finally(() => {
                                    pProgramSeasonSponsor.deleteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-programSeasonSponsor-swal-break');
                            break;
                    }
                })
        },

        // modal
        openModalRegisterSponsor () {
            this.setRegisterSponsorData()
            this.$bvModal.show('modal-create-program-season-sponsor')
        },
        closeModalRegisterSponsor () {
            this.$bvModal.hide('modal-create-program-season-sponsor')
        },

        setRegisterSponsorData() {
            this.registerSponsorDataLoading = true

            getSponsorJobOffersByProgram(this.programSeason.program_id)
                .then(({ data }) => {
                    this.registerSponsorData = data.data.filter(el => {
                        let setLikeOption = 'si' 
                        for (const programSeasonSponsor of this.tableData) {
                            if (el.attributes.sponsor_id == programSeasonSponsor.attributes.sponsor_id) {
                                setLikeOption = 'no'
                                break
                            }
                        }
                        return setLikeOption == 'si'
                    })
                })
                .finally(() => {
                    this.registerSponsorDataLoading = false
                })
        },

        programSeasonSponsorRegister(pSponsorJobOffer) {
            this.$set(pSponsorJobOffer, 'registerLoading', true)
            let IdProgramSeason = this.$route.params.IdProgramSeason

            let ProgramSeasonSponsorForm = new FormData()
            ProgramSeasonSponsorForm.append('programSeason_id', IdProgramSeason)
            ProgramSeasonSponsorForm.append('sponsor_id', pSponsorJobOffer.attributes.sponsor_id)
            ProgramSeasonSponsorForm.append('programSeasonSponsor_code', `${IdProgramSeason}-${pSponsorJobOffer.attributes.sponsor_id}`)   // programSeason_id - sponsor_id
            ProgramSeasonSponsorForm.append('show_excel', 'no')
            ProgramSeasonSponsorForm.append('excel_identifier', '')
            ProgramSeasonSponsorForm.append('gid', '')
            //
            ProgramSeasonSponsorForm.append('sponsorJobOffer_id', pSponsorJobOffer.id)

            storeProgramSeasonSponsor(ProgramSeasonSponsorForm)
                .then(() => {
                    swal('Asignación correcta!', 'Sponsor registrado .', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.getData()
                                    this.closeModalRegisterSponsor()
                                    break;
                                default:
                                    console.log('store programSeasonSponsor swal-break');
                                    break;
                            }
                        })
                })
                .finally(() => {
                    pSponsorJobOffer.registerLoading = false
                })
        }

    }
}
</script>