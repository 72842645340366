<template>
    <program-sponsors-list routeName="employee-aupair-sponsor-details"></program-sponsors-list>
</template>

<script>
import ProgramSponsorsList from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorsList.vue'

export default {
    name: "AupairSponsorList",
    components: {ProgramSponsorsList  },
    data: () => ({})
}
</script>