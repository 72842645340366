<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button d-none d-sm-block">
                                LISTA DE PARTICIPANTES
                            </h3>

                            <h3 class="mb-0 text-primary text-button d-block d-sm-none">
                                PART.
                            </h3>
                        </b-col>

                        <b-col cols="5" sm="3" lg="2">
                            <pulse-loader
                                :loading="getSeasonOptionsLoading"
                                :color="'#5e72e4'"
                                :margin="'5px'"
                                :size="8"
                                class="pt-2 text-center"
                                style="border-radius:5px; border:1px solid #5e72e4"
                            />

                            <b-form-select
                                class="text-center"
                                size="sm"
                                v-model="seasonSelected"
                                :options="seasonOptions"
                                v-show="!getSeasonOptionsLoading"
                                @change="searchParticipant(1)"
                            />
                        </b-col>

                        <b-col cols="auto">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row class="mt-3" align-h="between">
                        <b-col cols="12" sm="6" lg="4">
                            <b-form @submit.prevent="searchParticipant(1)">
                                <b-form-row>
                                    <b-col cols="10">
                                        <b-form-group class="mb-0">
                                            <pulse-loader
                                                :loading="loadingTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="8"
                                                class="pt-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="input_to_search"
                                                v-show="!loadingTableData"
                                                size="sm"
                                                autocomplete="off"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col >
                                        <b-form-group class="mb-0">
                                            <b-button
                                                class="w-100"
                                                size="sm"
                                                variant="primary"
                                                type="submit"
                                            ><b-icon icon="search" class="m-0 p-0"/></b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>
                        </b-col>

                        <b-col cols="auto" class="mt-3 mt-sm-0">
                            <b-button size="sm" :variant="order_type != 'created' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('created')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-numeric-down-alt" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98z"/>
                                    <path d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'a-z' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('a-z')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                                    <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>

                            <b-button size="sm" :variant="order_type != 'z-a' ? 'ligth' : 'contract-payment-1'" @click="listOrderBy('z-a')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-alpha-down-alt" viewBox="0 0 16 16">
                                    <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V7z"/>
                                    <path fill-rule="evenodd" d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371h-1.781zm1.57-.785L11 9.688h-.047l-.652 2.156h1.351z"/>
                                    <path d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
                                </svg>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laodingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-if="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th class="alwaysShow details"></th>
                            <th class="alwaysShow name">Participante</th>
                            <th>Responsable</th>
                            <th>programa</th>
                            <th>temporada</th>

                            <!-- postulant-payments: entrevista -->
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>entrevista</div></th>
                            <!-- contrato -->
                            <th class="text-center bg-primary text-white"><div>contrato</div></th>
                            <!-- contract-payments:  -->
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>primera cuota</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>segunda cuota</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>tercera cuota</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>cuarta cuota</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>quinta cuota</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>sevis</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>recojo visa</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>DERECHO CONSULAR</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>BUEN COMPORTAMIENTO</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>WWCE FEES</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>WWCE HOUSING</div></th>
                            <th class="text-center bg-primary text-white"><div>pago de</div><div>IENA INSURANCE</div></th>

                            <th class="text-center bg-primary text-white"></th>

                            <!-- entrevista -->
                            <th class="text-center bg-magenta text-white"><div>primera</div><div>entrevista</div></th>
                            <th class="text-center bg-magenta text-white"><div>segunda</div><div>entrevista</div></th>
                            <th class="text-center bg-magenta text-white"></th>

                            <!-- ficha -->
                            <th class="text-center bg-schedule-english-2 text-white"><div>ficha de</div><div>registro</div></th>
                            <th class="text-center bg-schedule-english-2 text-white"></th>

                            <!-- documentos grupo uno -->
                            <th class="text-center bg-naranja text-white" v-for="(g1, i) in programDocumentsSorted.group_one" :key="`${i}-g1`">{{ g1.name }}</th>
                            <th class="text-center bg-naranja text-white"><div>grupo uno</div><div>estado</div></th>
                            <th class="text-center bg-naranja text-white"><div>grupo uno</div><div>comentarios</div></th>
                            <th class="text-center bg-naranja text-white"></th>

                            <!-- documentos grupo dos -->
                            <th class="text-center bg-cielo text-white" v-for="(g2, i) in programDocumentsSorted.group_two" :key="`${i}-g2`">{{ g2.name }}</th>
                            <th class="text-center bg-cielo text-white"><div>grupo dos</div><div>estado</div></th>
                            <th class="text-center bg-cielo text-white"><div>grupo dos</div><div>comentarios</div></th>
                            <th class="text-center bg-cielo text-white"></th>

                            <!-- documentos grupo tres -->
                            <th class="text-center bg-magenta text-white" v-for="(g3, i) in programDocumentsSorted.group_three" :key="`${i}-g3`">{{ g3.name }}</th>
                            <th class="text-center bg-magenta text-white">.</th>

                            <!-- documentos grupo cuatro -->
                            <th class="text-center bg-schedule-english-2 text-white" v-for="(g4, i) in programDocumentsSorted.group_four" :key="`${i}-g4`">{{ g4.name }}</th>
                            <th class="text-center bg-schedule-english-2 text-white">.</th>

                            <!-- attempts -->
                            <th class="text-center bg-contract-1 text-white"><div></div><div>Sponsor</div></th>
                            <th class="text-center bg-contract-1 text-white"><div></div><div>postulaciones</div></th>
                            <th class="text-center bg-contract-1 text-white"><div></div><div>Jobs</div></th>
                        </template>

                        <template slot-scope="{row, index}">
                            <td>
                                <span v-if="order_type == 'created'">
                                    <!-- numeración descendente -->
                                    {{ tableMeta.total - ((tableMeta.per_page * (tableMeta.current_page - 1)) + (index + 1)) + 1 }}
                                </span>
                                <span v-else>{{ index + 1 }}</span>
                            </td>
                            <td class="alwaysShow details text-right">
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-details',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots"></b-icon>
                                </b-btn>
                            </td>
                            <td class="alwaysShow name">{{ row.attributes.participant_full_name }}</td>

                            <td>
                                <div v-if="row.attributes.postulant_salesAdvisor_id">
                                    <span class="text-primary">ventas :</span> {{ row.attributes.postulant_salesAdvisor_full_name }}
                                </div>

                                <div>
                                    <span class="text-primary">cultural :</span> {{ row.attributes.culturalAdvisor_full_name }}
                                </div>
                            </td>

                            <td>{{ row.attributes.program}}</td>
                            <td>{{ row.attributes.season}}</td>

                            <!-- postulant-payments: entrevista TO DELETE-->
                            <!-- <td>
                                <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon
                                        stacked icon="square-fill" scale="1.1"
                                        :variant="row.attributes.postulantPayments[0].attributes.status == 'verde' ? 'green' : (row.attributes.postulantPayments[0].attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                    ></b-icon>
                                </b-iconstack>

                                <span class="text-caption text-muted">
                                    {{ row.attributes.postulantPayments[0].attributes.amount_format }}
                                </span>
                            </td> -->

                            <!-- payments: entrevista -->
                            <td>
                                <postulant-payment-cell-component :row="row.attributes"/>
                            </td>

                            <!-- contrato: -->
                            <td class="text-center">
                                <b-iconstack font-scale="1.3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contract`" class="mb-3">
                                    <b-iconstack font-scale="1.3" class="mr-3">
                                        <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon stacked :icon="contract.attributes.status == 'valido' ? 'check' : 'x'" scale="1.6" variant="primary"></b-icon>
                                    </b-iconstack>

                                    <span class="text-caption text-muted" v-if="row.attributes.contracts.length != 0">
                                        {{ row.attributes.contracts[0].attributes.total_cost_format }}
                                    </span>
                                </div>
                            </td>
                            <!-- contract-payments: -->
                            <!-- primera cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'Primera Cuota'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>

                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'Primera Cuota'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- segunda cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'Segunda Cuota'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>

                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'Segunda Cuota'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- tercera cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'Tercera Cuota'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>

                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'Tercera Cuota'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- cuarta cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'Cuarta Cuota'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'Cuarta Cuota'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- quinta cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'Quinta Cuota'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'Quinta Cuota'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- sevis cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'SEVIS'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'SEVIS'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- visa cuota -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'RECOJO/VISA'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'RECOJO/VISA'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- DERECHO CONSULAR -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'DERECHO CONSULAR'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'DERECHO CONSULAR'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- BUEN COMPORTAMIENTO -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'BUEN COMPORTAMIENTO'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'BUEN COMPORTAMIENTO'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- WWCE FEES -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'WWCE FEES'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'WWCE FEES'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- WWCE HOUSING -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'WWCE HOUSING'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'WWCE HOUSING'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <!-- IENA INSURANCE -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3" v-if="row.attributes.contracts.length == 0">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter"></b-icon>
                                </b-iconstack>

                                <div v-for="(contract, i) in row.attributes.contracts" :key="`${i}-contrac`">
                                    <div v-for="(contractPayment, i) in contract.attributes.contractPayments" :key="`${i}-cp`">
                                        <b-iconstack
                                            v-if="contractPayment.attributes.payment_concept == 'IENA INSURANCE'"
                                            font-scale="1.3" class="mr-3"
                                        >
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter" ></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="contractPayment.attributes.status == 'verde' ? 'green' : (contractPayment.attributes.status == 'amarillo' ? 'yellow' : 'danger')">
                                            </b-icon>
                                        </b-iconstack>
                                        <span
                                            v-if="contractPayment.attributes.payment_concept == 'IENA INSURANCE'"
                                            class="text-caption text-muted"
                                        >
                                            {{ contractPayment.attributes.amount_format }}
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-right">
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-payments-vouchers',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <b-icon icon="receipt" scale="1.4"></b-icon>
                                </b-btn>
                            </td>

                            <!-- entrevistas -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter" v-if="row.attributes.primeraEntrevista == null"></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="yellow" v-else-if="row.attributes.primeraEntrevista.schedule_status == 'pendiente'"></b-icon>
                                    <b-icon stacked scale="1.6" variant="primary" v-else
                                        :icon="row.attributes.primeraEntrevista.interview_status == 'aprobado' ? 'check' : 'x'"
                                    ></b-icon>
                                </b-iconstack>

                                <span class="text-caption text-muted" v-if="row.attributes.primeraEntrevista != null && row.attributes.primeraEntrevista.interview_status != null">
                                    {{ row.attributes.primeraEntrevista.interview_status }}
                                </span>
                            </td>
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3">
                                   <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="lighter" v-if="row.attributes.segundaEntrevista == null"></b-icon>
                                    <b-icon stacked icon="square-fill" scale="1.1" variant="yellow" v-else-if="row.attributes.segundaEntrevista.schedule_status == 'pendiente'"></b-icon>
                                    <b-icon stacked scale="1.6" variant="primary" v-else
                                        :icon="row.attributes.segundaEntrevista.interview_status == 'aprobado' ? 'check' : 'x'"
                                    ></b-icon>
                                </b-iconstack>

                                <span class="text-caption text-muted" v-if="row.attributes.segundaEntrevista != null && row.attributes.segundaEntrevista.interview_status != null">
                                    {{ row.attributes.segundaEntrevista.interview_status }}
                                </span>
                            </td>

                            <td>
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-interviews',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-magenta"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="magenta"></b-icon>
                                </b-btn>
                            </td>

                            <!-- ficha -->
                            <td>
                                <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="check" scale="1.6" variant="primary" v-if="row.attributes.information_status == 'rellenado'"></b-icon>
                                    <b-icon stacked icon="x" scale="1.6" variant="primary" v-else></b-icon>
                                </b-iconstack>

                                <span class="text-caption text-muted">
                                    {{ row.attributes.information_status }}
                                </span>
                            </td>
                            <td>
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-ficha',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-schedule-english-2"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="schedule-english-2"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo uno -->
                            <td class="text-center apk-doc-group" v-for="(g1, i) in programDocumentsSorted.group_one" :key="`${i}-g1-field`">
                                <div v-for="(docGroupOne, i) in row.attributes.grupo_uno" :key="`${i}-docGroupOne`">
                                    <div v-if="g1.name == docGroupOne.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGroupOne.attributes.status == 'verde' ? 'green' : (docGroupOne.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGroupOne.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-center">
                                <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="x" scale="1.7" variant="primary" v-if="row.attributes.doc_group_one_status == 'no presentado'"></b-icon>
                                    <b-icon stacked icon="check" scale="1.6" variant="primary" v-else></b-icon>
                                </b-iconstack>
                            </td>

                            <td>
                                {{ row.attributes.doc_group_one_comments ? row.attributes.doc_group_one_comments : '...' }}
                            </td>

                            <td>
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-documents',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-naranja"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="naranja"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo dos -->
                            <td class="text-center apk-doc-group" v-for="(g2, i) in programDocumentsSorted.group_two" :key="`${i}-g2-field`">
                                <div v-for="(docGrouptwo, i) in row.attributes.grupo_dos" :key="`${i}-docGrouptwo`">
                                    <div v-if="g2.name == docGrouptwo.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGrouptwo.attributes.status == 'verde' ? 'green' : (docGrouptwo.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGrouptwo.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>


                            <td class="text-center">
                                <b-iconstack font-scale="1.3" class="mr-3">
                                    <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                    <b-icon stacked icon="x" scale="1.6" variant="primary" v-if="row.attributes.doc_group_two_status == 'no presentado'"></b-icon>
                                    <b-icon stacked icon="check" scale="1.6" variant="primary" v-else></b-icon>
                                </b-iconstack>
                            </td>

                            <td>
                                {{ row.attributes.doc_group_two_comments  ? row.attributes.doc_group_two_comments : '...'}}
                            </td>

                            <td>
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-documents',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-cielo"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="cielo"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo tres -->
                            <td class="text-center apk-doc-group" v-for="(g3, i) in programDocumentsSorted.group_three" :key="`${i}-g3-field`">
                                <div v-for="(docGroupthree, i) in row.attributes.grupo_tres" :key="`${i}-docGroupthree`">
                                    <div v-if="g3.name == docGroupthree.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGroupthree.attributes.status == 'verde' ? 'green' : (docGroupthree.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGroupthree.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-center">
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-documents',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-magenta"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="magenta"></b-icon>
                                </b-btn>
                            </td>

                            <!-- Documentos grupo cuatro -->
                            <td class="text-center apk-doc-group" v-for="(g4, i) in programDocumentsSorted.group_four" :key="`${i}-g4-field`">
                                <div v-for="(docGroupFour, i) in row.attributes.grupo_cuatro" :key="`${i}-docGroupFour`">
                                    <div v-if="g4.name == docGroupFour.attributes.name">
                                        <b-iconstack font-scale="1.3" class="mr-3 apk-doc">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon stacked icon="square-fill" scale="1.1"
                                                :variant="docGroupFour.attributes.status == 'verde' ? 'green' : (docGroupFour.attributes.status == 'amarillo' ? 'yellow' : 'danger')"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span class="apk-doc-dump">{{ docGroupFour.attributes.name }}</span>
                                    </div>
                                </div>
                            </td>

                            <td class="text-center">
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participant-documents',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-schedule-english-2"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="schedule-english-2"></b-icon>
                                </b-btn>
                            </td>

                            <!-- sponsor -->
                            <td >
                                <span v-if="row.attributes.postulations.length != 0"
                                    >{{ row.attributes.postulations[0].attributes.sponsor_name }}
                                </span>
                                <span v-else>- - - - -</span>
                            </td>
                            <!-- attempts -->
                            <td class="">
                                <div v-for="(postulation,i) in row.attributes.postulations" :key="`${i}-post`" v-show="postulation.attributes.status != 'aceptado'">
                                        <b-iconstack font-scale="1.3" class="mr-3">
                                            <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                            <b-icon
                                                stacked icon="square-fill" scale="1.1"
                                                :variant="postulation.attributes.status == 'nuevo' ? 'yellow' : (postulation.attributes.status == 'en proceso' ? 'yellow' : (postulation.attributes.status == 'aceptado' ? 'green' : 'magenta'))"
                                            ></b-icon>
                                        </b-iconstack>
                                        <span>{{ postulation.attributes.subject }} : </span>
                                        <span>{{ postulation.attributes.programSponsorJob.attributes.job_name }}</span>
                                </div>
                            </td>

                            <!-- attempt accepted -->
                            <!-- <td class="text-center">
                                <div v-for="(postulation,i) in row.attributes.postulations" :key="`${i}-post`" v-show="postulation.attributes.status == 'aceptado'">
                                    <b-iconstack font-scale="1.3">
                                        <b-icon stacked icon="square" scale="1.6" variant="lighter"></b-icon>
                                        <b-icon
                                            stacked icon="square-fill" scale="1.1"
                                            :variant="postulation.attributes.status == 'nuevo' ? 'defaullt' : (postulation.attributes.status == 'en procesor' ? 'yellow' : (postulation.attributes.status == 'aceptado' ? 'green' : 'magenta'))"
                                        ></b-icon>
                                    </b-iconstack>
                                </div>
                            </td> -->

                            <td>
                                  <b-btn 
                                    :to="{
                                        name: 'employee-participants-attempts-list',
                                        params: { 
                                            'IdProgramSeasonParticipant': row.id, //IdProgramSeasonParticipant
                                            'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id //IdProgramSeasonPostulant
                                        }
                                    }"
                                    variant="outline-contract-1"
                                    size="sm"
                                >
                                    <b-icon icon="three-dots" scale="1.4" variant="contract-1"></b-icon>
                                </b-btn>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!-- pagination -->
                <div class="card-footer" v-show="!loadingTableData">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { seasonProgramSeasonParticipantSearch } from '@/api/Season/SeasonProgramSeasonParticipantSearch';
import { getAllProgramDocumentsBySeason } from '@/api/ProgramDocument/ProgramDocument';
import { getAllSeasons } from '@/api/Season/Season'
import PostulantPaymentCellComponent from '@/components/Admin-Employee/Postulant-Participant/List/PostulantPaymentCellComponent.vue';

export default {
    name: "AllParticipantListComponent",
    components: { PostulantPaymentCellComponent },
    data: () => ({
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
        //
        input_to_search: '',
        order_type: 'created',
        //
        seasonOptions: [],
        seasonSelected: 2,
        getSeasonOptionsLoading: false,
        //
        programDocumentsSorted: []
    }),
    created() {
        this.searchParticipant()
        this.getSeasonsData()
    },
    methods: {
        /**search */
        searchParticipant (pPage = 1) {
            this.loadingTableData = true
            let IdSeason = this.seasonSelected

            this.setDocuments(IdSeason)

            const SearchForm = new FormData()
            SearchForm.append('input_to_search', this.input_to_search)
            SearchForm.append('order_type', this.order_type)
            //
            SearchForm.append('test', 'no')

            seasonProgramSeasonParticipantSearch(IdSeason, SearchForm, pPage)
                .then(({ data }) => {
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .finally(() => {
                    this.loadingTableData = false
                })
        },

        setDocuments(pIdSeason) {
            getAllProgramDocumentsBySeason(pIdSeason)
                .then(({data}) => {
                    this.programDocumentsSorted = data
                })
        },

        paginationCLiked (e) {
            this.searchParticipant(e)
        },

        // get all seasons
        getSeasonsData() {
            this.getSeasonOptionsLoading = true
            getAllSeasons()
                .then(({data}) => {
                    this.seasonOptions = data.data.map(el => ({value: el.attributes.id, text: el.attributes.season}))
                })
                .finally(() => {
                    this.getSeasonOptionsLoading = false
                })
        },

        listOrderBy(pType) {
            this.order_type = pType
            this.searchParticipant()
        },

        reloadData () {
            this.searchParticipant()
            this.getSeasonsData()
        },
    }
}
</script>

<style scoped>
.apk-doc-group {
    position: relative;
}
.apk-doc-dump {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 4px 8px;
    background: #000;
    border-radius: 5px;
    font-size: 10pt;
    color: white;
    opacity: .75;
    transition: .300s ease-in all;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0);
}
.apk-doc:hover ~ .apk-doc-dump {
    transform: translate(-50%, -50%) scale(1);
}
/*  */
.alwaysShow {
    /* background-color: rgb(94, 114, 228, .05); */
    z-index: 100;
}
@media (min-width: 576px) {
    .alwaysShow {
        position: sticky;
        background-color: #f7f8fe;
        border-bottom: 2px solid #e9ecef !important;
    }
}
.alwaysShow.details {
    left: 0px;
}
.alwaysShow.name {
    left: 80px;
}
</style>