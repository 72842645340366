<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Documentos
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="green" size="sm" @click="modalAddDocumentClick">
                                <b-icon icon="plus"></b-icon>
                            </b-button>

                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>

                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:250px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

                <div class="table-responsive" v-show="!loadingTableData">
                    <table class="table tablesorter align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>#</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="text-center">estado</th>
                                <th class="text-center">DOC </th>
                                <th>Nombre de Doc. </th>
                                <th class="text-center">grupo </th>
                                <th class="text-center">auto creación </th>
                                <th>descripción </th>
                            </tr>
                        </thead>

                        <tbody class="list">
                            <tr v-for="(item, i) in tableDataSorted" :key="i+'tds'">
                                <td>{{ i + 1 }}</td>

                                <td class="text-center">
                                    <b-btn variant="transparent" size="sm" @click="modalDocumentEditClick(item.attributes)"><b-icon icon="pencil-square" scale="1.2" variant="naranja"></b-icon></b-btn>
                                </td>
                                
                                <td class="text-center">
                                    <b-btn variant="transparent" size="sm" @click="modalAddParticipantsClick(item.attributes.id)"><b-icon icon="person" scale="1.2" variant="cielo"></b-icon></b-btn>
                                </td>

                                <td class="text-center">
                                    <pulse-loader
                                        :loading="item.deleteLoading || false"
                                        :color="'#EF4A81'"
                                        :margin="'2px'"
                                        :size="8"
                                        class="pt-2 pb-1"
                                    ></pulse-loader>

                                    <b-btn variant="transparent" size="sm"
                                        v-show="!item.deleteLoading && item.attributes.count_participant_document_state_yellow_green == 0"
                                        @click="deleteProgramDocument(i, item)"
                                    ><b-icon icon="trash" scale="1.1" variant="magenta"></b-icon>
                                    </b-btn>
                                </td>

                                <td class="text-center"><badge class="badge-dot"><i :class="item.attributes.status == 'activado' ? 'bg-green' : 'bg-danger'"></i></badge></td>

                                <td class="text-center">
                                    <b-button :variant="item.attributes.file == '' ? 'outline-light' : 'outline-primary'"
                                        :disabled="item.attributes.file == '' ? true : false"
                                        size="sm" :href="item.attributes.file" target="_black">
                                        <b-icon icon="file-earmark-text" scale="1.2"></b-icon>
                                    </b-button>
                                </td>

                                <td>{{ item.attributes.name }}</td>

                                <td :class="[item.attributes.group_name == 'UNO' ? 'text-naranja' : (item.attributes.group_name == 'DOS' ? 'text-cielo' : (item.attributes.group_name == 'TRES' ? 'text-contract-payment-1' : 'text-schedule-english-2'))]" class="text-center">
                                    {{ item.attributes.group_name }}
                                </td>

                                <td :class="[item.attributes.auto_create == 'si' ? 'text-primary' : 'text-light']" class="text-center">{{ item.attributes.auto_create }}</td>

                                <td>{{ item.attributes.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-card>
        </b-col>

        <!-- modal document edit -->
        <b-modal
            id="modal-document-edit"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            body-class="pt-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">EDITAR DOCUMENTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalDocumentEditClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <b-form @submit.prevent="editDocument">
                                <!-- name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group label="Nombre: ">
                                            <b-form-input type="text" v-model="documentSelected.name" disabled/>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- group_name -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group label="Grupo: ">
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="documentSelected.group_name"
                                                :options="[{ value: 'UNO', text: 'UNO'}, { value: 'DOS', text: 'DOS'}, { value: 'TRES', text: 'TRES'}, { value: 'CUATRO', text: 'CUATRO'}]"
                                                :state="showModalInputStatus('group_name')"
                                                v-show="!documentEditLoading || false"
                                                @change="evalAutoComplete">
                                            </b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('group_name')"
                                                :key="`${i}-group_name`"
                                            >{{ inputError }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- switch -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group
                                            :label="documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD' ? '¿Actualizar link?' : '¿Actualizar documento?'"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-checkbox
                                                v-model="switchDocument"
                                                v-show="!documentEditLoading"
                                                switch
                                                value="si"
                                                unchecked-value="no"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- file -->
                                <transition name="slide-fade">
                                <b-form-row v-if="switchDocument == 'si'">
                                    <b-col>
                                        <b-form-group
                                            :label="documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD' ? 'Link del video' : 'Archivo'"
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <!-- video-url -->
                                            <b-form-input
                                                placeholder="Ingrese el link del video."
                                                v-model="link"
                                                type="url"
                                                :state="showModalInputStatus('link')"
                                                v-show="!documentEditLoading && (documentSelected.name == 'VIDEO' || documentSelected.name == 'VIDEO APLICACIÓN LIFEGUARD')"
                                            />
                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('link')"
                                                :key="`${i}-edit-resource-link`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>

                                            <!-- archivo -->
                                            <b-form-file
                                                v-model="file"
                                                :state="showModalInputStatus('file')"
                                                v-show="!documentEditLoading && documentSelected.name != 'VIDEO' && documentSelected.name != 'VIDEO APLICACIÓN LIFEGUARD'"
                                                style="overflow:hidden;"
                                            />
                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('file')"
                                                :key="`${i}-edit-resource-file`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                                </transition>


                                <!-- description -->
                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Descripción:" 
                                        >
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-textarea 
                                                placeholder="Ingrese una descripción (opcional)"
                                                v-model="documentSelected.description"
                                                :state="showModalInputStatus('description')"
                                                v-show="!documentEditLoading"
                                                rows="3"
                                                max-rows="6"
                                            />

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('description')"
                                                :key="`${i}-create-resource-description`"
                                            >
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- auto_create -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group label="Registro automático al registrar al participante :">
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="text-center pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            ></pulse-loader>

                                            <b-form-radio-group
                                                :options="[{ value: 'no', text: 'No' },{ value: 'si', text: 'Si' },]"
                                                v-model="documentSelected.auto_create"
                                                :state="showModalInputStatus('auto_create')"
                                                v-show="!documentEditLoading"
                                                stacked plain
                                                :disabled="documentSelected.group_name == 'TRES' || documentSelected.group_name == 'CUATRO'"
                                            ></b-form-radio-group>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('auto_create')"
                                                :key="`${i}-auto_create`"
                                            >{{ inputError }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <!-- status -->
                                <b-form-row>
                                    <b-col>
                                        <b-form-group label="Estado :">
                                            <pulse-loader
                                                :loading="documentEditLoading"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-select
                                                v-model="documentSelected.status"
                                                :state="showModalInputStatus('status')"
                                                v-if="!documentEditLoading"
                                                :options="[{ value: 'activado', text: 'Activado'}, { value: 'desactivado', text: 'Desactivado'}]"
                                            ></b-form-select>

                                            <b-form-invalid-feedback
                                                v-for="(inputError, i) in showModalInputErrors('status')"
                                                :key="`${i}-status`"
                                            >{{ inputError }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-row align-v="center" align-h="center">
                                    <b-col cols="auto">
                                        <b-button variant="primary" type="submit" :disabled="documentEditLoading">ACTUALIZAR</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- modal add document -->
        <b-modal
            id="modal-add-document"
            centered
            hide-footer
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            body-class="mt-0 pt-0"
        >
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">AGREGAR DOCUMENTO</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddDocumentClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <template #default>
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <div style="height:150px;" v-show="setLoading">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>
                        </b-col>

                        <b-col v-if="!setLoading" cols="12">
                            <div v-for="(doc, i) in documentsToAdd" :key="`${i}-doc`">
                                <b-list-group>
                                    <b-list-group-item>
                                        <b-row v-show="doc.addLoading ? true : false">
                                            <div style="height:30px;">
                                                <pulse-loader :color="'#5e72e4'" :margin="'10px'" :size="12" style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)">
                                                </pulse-loader>
                                            </div>
                                        </b-row>

                                        <b-row v-show="doc.addLoading ? false : true">
                                            <b-col>{{ doc.attributes.name }}</b-col>
                                            <b-col cols="auto" :class="[doc.attributes.group_name == 'UNO' ? 'text-naranja' : (doc.attributes.group_name == 'DOS' ? 'text-cielo' : 'text-contract-payment-1')]">{{ doc.attributes.group_name }}</b-col>
                                            <b-col cols="auto">
                                                <b-button variant="outline-primary" size="sm" @click="addProgram(doc, i)">
                                                    <b-icon icon="plus" scale="1.5"></b-icon>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-list-group>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>

        <!-- modal add participant -->
        <b-modal
            id="modal-add-participants"
            centered
            hide-footer
            size="xl"
            no-close-on-backdrop
            no-close-on-esc
            body-class="pt-0"
        >
            <!-- modal header -->
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">SELECCIONAR PARTICIPANTEs</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalAddParticipantsClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-row>
                        <!-- search participant -->
                        <b-col cols="12" lg="6">
                            <b-form @submit.prevent="searchParticipant">
                                <b-form-row v-if="paginated_by != 'todos'">
                                    <b-col cols="10">
                                        <b-form-group>
                                            <pulse-loader
                                                :loading="loadingParticipantsTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-form-input
                                                type="text"
                                                placeholder="Búsqueda por nombres o dni."
                                                v-model="inputToSearch"
                                                :state="showParticipantsModalInputStatus('input_to_search')"
                                                v-show="!loadingParticipantsTableData"
                                            />

                                            <b-form-invalid-feedback v-for="(inputError, i) in showParticipantsModalInputErrors('input_to_search')" :key="`${i}-serchUsers`">
                                                {{ inputError }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="2">
                                        <b-form-group>
                                            <b-button
                                                class="w-100 py-auto px-0"
                                                variant="primary"
                                                type="submit"
                                            ><b-icon icon="search" class="m-0 p-0"/></b-button>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>

                                <b-form-row>
                                    <b-col cols="12">
                                        <b-form-group>
                                            <pulse-loader
                                                :loading="loadingParticipantsTableData"
                                                :color="'#5e72e4'"
                                                :margin="'5px'"
                                                :size="10"
                                                class="pt-2 pb-1 pl-2 text-center"
                                                style="border-radius:5px; border:1px solid #5e72e4"
                                            />

                                            <b-input-group v-show="!loadingParticipantsTableData">
                                                <b-input-group-prepend>
                                                    <b-input-group-text > Paginación : </b-input-group-text>
                                                </b-input-group-prepend>

                                                <b-form-select
                                                    class="text-center"
                                                    v-model="paginated_by"
                                                    :options="[{value:'todos', text:'Todos'}, {value:10, text:'10'}, {value:20, text:'20'}, {value:30, text:'30'}]"
                                                    :state="showParticipantsModalInputStatus('paginated_by')"
                                                    @change="searchParticipant(1)"
                                                />

                                                <b-form-invalid-feedback v-for="(inputError, i) in showParticipantsModalInputErrors('paginated_by')" :key="`${i}-serchUsers`">
                                                    {{ inputError }}
                                                </b-form-invalid-feedback>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                </b-form-row>
                            </b-form>

                            <div style="height:150px;" v-show="loadingParticipantsTableData">
                                <pulse-loader
                                    :color="'#5e72e4'"
                                    :margin="'10px'"
                                    :size="20"
                                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                            </div>

                            <!-- cultural participants -->
                            <b-card no-body>
                                <div class="table-responsive mb-0" v-show="!loadingParticipantsTableData && !searchFailed" style="max-height:400px;overflow-y:auto">
                                    <base-table class="table align-items-center table-flush"
                                        thead-classes="thead-light"
                                        tbody-classes="list"
                                        :data="participantTableData"
                                    >
                                        <template slot="columns">
                                            <th class="alway-title-show" v-if="paginated_by == 'todos'">
                                                <div>
                                                    <b-button size="sm" variant="primary" disabled>{{participantStackChecked.length}}</b-button>

                                                    <b-button size="sm" :variant="participantStackChecked.length == participantTableData.length ? 'primary' : 'light'" 
                                                        @click="addAllParticipantsToggle">All</b-button>
                                                </div>
                                            </th>
                                            <th class="alway-title-show" v-if="paginated_by != 'todos'"></th>
                                            <th class="alway-title-show">Nombres</th>
                                            <th class="alway-title-show">Programa</th>
                                            <th class="alway-title-show">temporada</th>
                                        </template>

                                        <template slot-scope="{row, index}">
                                            <td v-if="paginated_by == 'todos'">
                                                <b-form-checkbox class="participant-checkbox"
                                                    :value="{...row.attributes, index}" @change="(e) => stackToParticipantChecked(e, index)"></b-form-checkbox>
                                            </td>

                                            <td class="text-center"  v-if="paginated_by != 'todos'">
                                                <b-btn 
                                                    variant="outline-green"
                                                    size="sm"
                                                    @click="choseParticipant(row.attributes, index)"
                                                ><b-icon icon="person-plus-fill" class="m-0 p-0"/>
                                                </b-btn>
                                            </td>
                                            <td class="text-uppercase">
                                                <span>{{ row.attributes.user_last_name }} {{ row.attributes.user_name }}</span>
                                            </td>
                                            <td>{{ row.attributes.program}}</td>
                                            <td>{{ row.attributes.season}}</td>
                                        </template>
                                    </base-table>
                                </div>
                            </b-card>

                            <div class="card-footer border-0" v-if="!loadingParticipantsTableData && !searchFailed && paginated_by != 'todos'">
                                <base-pagination
                                    class="mb-0"
                                    align="center"
                                    :pageCount="participantTableMeta.last_page" 
                                    :perPage="participantTableMeta.per_page"
                                    :value="participantTableMeta.current_page"
                                    @input="paginationCLiked"
                                />
                            </div>
                        </b-col>

                        <!-- participants selected -->
                        <b-col cols="12" lg="6">
                            <b-card border-variant="primary">
                                <!-- selectedParticipants -->
                                <b-row style="max-height:400px;overflow-y:auto" v-if="paginated_by != 'todos'">
                                    <b-col
                                        v-for="(participant, i) in selectedParticipants" :key="`${i}-participante`"
                                        cols="12"
                                        class="d-flex justify-content-between align-items-center mb-1"
                                    >
                                        <span class="text-uppercase" style="font-size: 12px">
                                            <b-button size="sm" variant="outline-light" class="mr-2">{{ i + 1 }}</b-button>
                                            {{ participant.user_last_name }} {{ participant.user_name }}
                                        </span>

                                        <b-button variant="danger" size="sm" @click="() => {
                                                participantTableData.push({ attributes : ({...participant})})
                                                selectedParticipants.splice(i, 1)
                                            }"
                                            :disabled="registerProgramDocumentLoading">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </b-button>
                                    </b-col>
                                </b-row>

                                <!-- participantStackChecked -->
                                <b-row style="max-height:400px;overflow-y:auto" v-if="paginated_by == 'todos'">
                                    <b-col
                                        v-for="(participant, i) in participantStackChecked" :key="`${i}-participante-sc`"
                                        cols="12"
                                        class="d-flex justify-content-between align-items-center mb-1"
                                    >
                                        <span class="text-uppercase" style="font-size: 12px">
                                            <b-button size="sm" variant="outline-light" class="mr-2">{{ i + 1 }}</b-button>
                                             {{ participant.user_last_name }} {{ participant.user_name }}
                                        </span>
                                    </b-col>
                                </b-row>

                                <!-- button add -->
                                <b-row class="mt-5" align-h="center">
                                    <b-col cols="auto">
                                        <pulse-loader
                                            :loading="registerProgramDocumentLoading"
                                            :color="'#5e72e4'"
                                            :margin="'5px'"
                                            :size="10"
                                            class="pt-2 pb-1 pl-2 text-center"
                                        />

                                        <b-button @click="addDocumentToParticipants" size="md" 
                                            v-show="!registerProgramDocumentLoading && (selectedParticipants.length > 0 || participantStackChecked.length > 0)" variant="primary">
                                            AGREGAR DOCUMENTO
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { getProgramSeason } from '@/api/ProgramSeason/ProgramSeason';
import { getGeneralDocuments } from '@/api/GeneralDocument/GeneralDocument';
import { getProgramDocuments } from '@/api/ProgramSeason/ProgramSeasonProgramDocuments';
import { seasonProgramSeasonParticipantSearch } from '@/api/Season/SeasonProgramSeasonParticipantSearch'
import { storeProgramDocument,updateProgramDocument, destroyProgramDocument } from '@/api/ProgramDocument/ProgramDocument'
import { registerDocumentToParticipants } from '@/api/ParticipantDocument/RegisterDocumentToParticipants'
import swal from 'sweetalert'

export default {
    name: "ProgramDocuments",
    data: () => ({
        //
        programSeason: {},
        // table 
        tableData: [],
        tableDataSorted: [],
        loadingTableData: false,
        // modal edit form
        documentSelected: {},
        file : null,
        link : '',
        switchDocument: 'no',
        //
        documentsToAdd: [],
        setLoading: false,
        //
        initialModalEditInputValues : true,
        modalEditInputErrors: {},
        documentEditLoading: false,
        //--
        inputToSearch: '',
        order_type: 'created',
        searchFailed: true,
        paginated_by: 10,

        participantTableData: [],
        participantTableMeta: {last_page:0,per_page:0,current_page:0},

        selectedParticipants: [],
        participantstableData: [],

        participantStackChecked: [],

        initialModalInputValues: true,
        loadingParticipantsTableData: false,
        modalParticipantsInputErrors: {},

        IdProgramDocumentCreated: '',

        registerProgramDocumentLoading: false
    }),
    created() {
        this.getProgramDocumentsData()
    },
    computed: {
        culturalAdvisorPermissionData () {
            return this.$store.getters['user/getEmployeePermissionData']('asesor-cultural')
        },
    },
    methods: {
        getProgramDocumentsData () {
            this.loadingTableData = true
            let IdProgramSeason = this.$route.params.IdProgramSeason  // ok

            getProgramSeason(IdProgramSeason)
                .then(({ data }) => {
                    this.programSeason = data.data.attributes

                    getProgramDocuments(IdProgramSeason)
                        .then(({ data }) => {
                            this.tableDataSorted = data.data
                            this.sortTable()
                        })
                        .finally(() => {
                            this.loadingTableData = false
                        })
                })
        },

        sortTable() {
            this.tableDataSorted = [
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'UNO'),
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'DOS'),
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'TRES'),
                ...this.tableDataSorted.filter(el => el.attributes.group_name == 'CUATRO'),
            ]
        },

        // Modal edit
        modalDocumentEditClick (pDocument) {
            this.documentSelected = { ...pDocument }
            this.$bvModal.show('modal-document-edit')
        },
        modalDocumentEditClose () {
            this.$bvModal.hide('modal-document-edit')
            this.clearInputsAndStates()
        },

        evalAutoComplete(e) {
            if (e == 'TRES' || e == 'CUATRO') {
                this.auto_create = 'no'
                Object.keys(this.documentSelected).length > 0 ? this.documentSelected.auto_create = 'no' : null
            } else {
                this.auto_create = 'si'
                Object.keys(this.documentSelected).length > 0 ? this.documentSelected.auto_create = 'si' : null
            }
        },

        editDocument () {
            this.modalEditInputErrors = {}
            this.initialModalEditInputValues = false
            this.documentEditLoading = true

            let IdProgramDocument = this.documentSelected.id  // ok

            let DocumentForm = new FormData()
            DocumentForm.append('.method', 'put')
            DocumentForm.append('group_name', this.documentSelected.group_name)
            DocumentForm.append('file', this.file)
            DocumentForm.append('link', this.link)
            DocumentForm.append('doc_name', this.documentSelected.name)
            DocumentForm.append('actualizar_documento', this.switchDocument)
            DocumentForm.append('description', this.documentSelected.description)
            DocumentForm.append('auto_create', this.documentSelected.auto_create)
            DocumentForm.append('status', this.documentSelected.status)

            updateProgramDocument(DocumentForm, IdProgramDocument)
                .then(() => {
                    swal('Actualización correcta!', 'Documento actualizado.', 'success')
                        .then(value => {
                            switch (value) {
                                case true:
                                case null:
                                    this.clearInputsAndStates()
                                    this.getProgramDocumentsData()
                                    this.$bvModal.hide('modal-document-edit')
                                    break;

                                default:
                                    console.log('editDocument--swal-break');
                                    break;
                            }
                        })
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalEditInputErrors = err.response.data.errors
                    }
                })
                .finally(() => {
                    this.documentEditLoading = false
                })
        },

        // Modal add
        modalAddDocumentClick () {
            this.setDocumentsToAdd()
            this.$bvModal.show('modal-add-document')
        },
        modalAddDocumentClose () {
            this.$bvModal.hide('modal-add-document')
        },

        setDocumentsToAdd() {
            this.setLoading = true

            getGeneralDocuments()
                .then(({ data }) => {
                    this.documentsToAdd = [
                        ...data.data.filter(el => el.attributes.group_name == 'UNO' && el.attributes.status == 'activado'),
                        ...data.data.filter(el => el.attributes.group_name == 'DOS' && el.attributes.status == 'activado'),
                        ...data.data.filter(el => el.attributes.group_name == 'TRES' && el.attributes.status == 'activado'),
                        ...data.data.filter(el => el.attributes.group_name == 'CUATRO' && el.attributes.status == 'activado')
                    ]
                    this.documentsToAdd = this.documentsToAdd.filter(el => {
                        let add = 'si'
                        this.tableDataSorted.forEach(el_table_sorted => {
                            if(el.attributes.name == el_table_sorted.attributes.name ) add = 'no'
                        })
                        return add == 'si'
                    })

                })
                .finally(() => {
                    this.setLoading = false
                })
        },

        addProgram(pGeneralDocument, pIndex) {
            this.$set(pGeneralDocument, 'addLoading', true)

            let DocumentForm = new FormData()
            DocumentForm.append('programSeason_id', this.$route.params.IdProgramSeason)
            DocumentForm.append('group_name', pGeneralDocument.attributes.group_name)
            DocumentForm.append('name', pGeneralDocument.attributes.name)
            DocumentForm.append('description', pGeneralDocument.attributes.description)
            DocumentForm.append('auto_create', pGeneralDocument.attributes.group_name == 'UNO' || pGeneralDocument.attributes.group_name == 'DOS' ? 'si' : 'no' )
            DocumentForm.append('status', 'activado')

            storeProgramDocument(DocumentForm)
                .then(({ data }) => {
                    this.tableDataSorted.push(data.data)
                    this.sortTable()
                    this.documentsToAdd.splice(pIndex, 1)
                    if (this.documentsToAdd.length == 0 ) this.modalAddDocumentClose()
                    this.modalAddParticipantsClick(data.data.attributes.id)
                })
                .finally(() => {
                    pGeneralDocument.addLoading = false
                })
        },

        //  modal add
        modalAddParticipantsClick(pIdProgramDocument) {
            this.clearParticipantsInputsAndStates()
            this.IdProgramDocumentCreated = pIdProgramDocument
            this.searchParticipant()
            this.$bvModal.show('modal-add-participants')
        },

        modalAddParticipantsClose() {
            this.$bvModal.hide('modal-add-participants')
            this.clearParticipantsInputsAndStates()
        },

        searchParticipant (pPage = 1) {
            if (this.paginated_by == 'todos') this.selectedParticipants = []
            this.participantStackChecked = []

            this.initialModalInputValues = false
            this.loadingParticipantsTableData = true
            this.modalParticipantsInputErrors = {}

            let SearchFormData = new FormData()
            SearchFormData.append('input_to_search', this.inputToSearch)
            SearchFormData.append('order_type', this.order_type)
            SearchFormData.append('paginated_by', this.paginated_by)
            SearchFormData.append('lite_resource', "si")

            seasonProgramSeasonParticipantSearch(this.programSeason.season_id, SearchFormData, pPage)
                .then(({data}) => {
                    this.participantTableData = data.data.filter(el => el.attributes.programSeason_id == this.$route.params.IdProgramSeason)

                    this.participantTableData = data.data.filter(el => {
                        let add = 'si'

                        if (el.attributes.programSeason_id == this.$route.params.IdProgramSeason) {

                            el.attributes.participantDocuments.forEach(pd => {
                                if (pd.programDocument_id == this.IdProgramDocumentCreated) add = 'no'
                            })
                        } else {
                            add = 'no'
                        }

                        return add == 'si'
                    })

                    this.participantTableData = this.participantTableData.filter(el => {
                        let add = 'si'

                        this.selectedParticipants.forEach(sel_part => {
                            if(sel_part.id == el.attributes.id) add = 'no'
                        });

                        return add == 'si'
                    })
                    this.participantTableMeta = data.meta
                    this.searchFailed = false
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.modalParticipantsInputErrors = err.response.data.errors
                        this.searchFailed = true
                    }
                })
                .finally(() => {
                    this.loadingParticipantsTableData = false
                })
        },

        choseParticipant (pParticipant, pIndex) {
            this.selectedParticipants.push({ ...pParticipant })
            this.participantTableData.splice(pIndex, 1)
        },

        stackToParticipantChecked(pParticipant, pIndex) {
            if(pParticipant) this.participantStackChecked.push({...pParticipant})

            if(pParticipant == false) {
                this.participantStackChecked.some((el, j) => {
                    if (el.index == pIndex) {
                        this.participantStackChecked.splice(j, 1)
                        return true
                    }
                })
            }
        },

        addAllParticipantsToggle() {
            this.participantStackChecked = []
            let isAllChecked = 'si'
            document.querySelectorAll('.participant-checkbox > input').forEach(el => {
                if (!el.checked) {
                    isAllChecked = 'no'
                    el.click()
                } else {
                    el.click()
                    el.click()
                }
            })
            if (isAllChecked == 'si') document.querySelectorAll('.participant-checkbox > input').forEach(el => el.click())
        },

        addDocumentToParticipants() {
            this.registerProgramDocumentLoading = true

            let RegisterForm = new FormData()
            RegisterForm.append('IdProgramDocument', this.IdProgramDocumentCreated)
            RegisterForm.append('participants', this.paginated_by != 'todos' ? this.selectedParticipants.map(el => el.id) : this.participantStackChecked.map(el => el.id) )

            registerDocumentToParticipants(RegisterForm)
                .then(() => {
                    this.modalAddParticipantsClose()
                    this.reloadData()
                    swal('Documento registrado.', '', 'success')
                })
                .finally(()=> {
                    this.registerProgramDocumentLoading = false
                })
        },

        paginationCLiked (e) {
            this.searchParticipant(e)
        },

        showParticipantsModalInputErrors (pInput) {
            if (Object.keys(this.modalParticipantsInputErrors).includes(pInput)) return this.modalParticipantsInputErrors[pInput]
            else return []
        },

        showParticipantsModalInputStatus (pInput) {
            if (this.initialModalInputValues) return null 
            else if (Object.keys(this.modalParticipantsInputErrors).includes(pInput)) return false
            else return true
        },

        clearParticipantsInputsAndStates () {
            this.initialModalInputValues = true
            this.modalParticipantsInputErrors = {}
            //
            this.inputToSearch = ''
            this.participantTableData = []
            this.participantTableMeta = {last_page:0,per_page:0,current_page:0}
            //
            this.selectedParticipants = []
            this.participantStackChecked = []
            //
            this.IdProgramDocumentCreated = ''
        },

        ///

        showModalInputErrors (pInput) {
            if (Object.keys(this.modalEditInputErrors).includes(pInput)) return this.modalEditInputErrors[pInput]
            else return []
        },

        showModalInputStatus (pInput) {
            if (this.initialModalEditInputValues) return null 
            else if (Object.keys(this.modalEditInputErrors).includes(pInput)) return false
            else return true
        },

        clearInputsAndStates () {
            this.initialModalEditInputValues = true
            this.modalEditInputErrors = {}
            //
            this.documentSelected = {}
            this.file = null
            this.switchDocument = 'no'
            this.link = ''
        },

        reloadData () {
            this.getProgramDocumentsData()
        },
        //
        deleteProgramDocument (pIndex, pProgramDocument) {
            swal('Se eliminará el documento y todos los documentos de los participantes con estado ROJO', '¿Desea eliminar?', 'error', { dangerMode: true, buttons: ['cancelar', 'Si, eliminar']})
                .then(value => {
                    switch (value) {
                        case true:
                            this.$set(pProgramDocument, 'deleteLoading', true)
                            destroyProgramDocument(pProgramDocument.attributes.id)
                                .then(() => {
                                    this.tableDataSorted.splice(pIndex, 1)
                                    swal('Borrado correctamente', '', 'success')
                                })
                                .finally(() => {
                                    pProgramDocument.deelteLoading = false
                                })
                            break;
                        case null:
                            break;
                        default:
                            console.log('delete-prom-doc-swal-break');
                            break;
                    }
                })
        },
    }
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all .5s ease;
}
.slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.alway-title-show {
    position: sticky;
    background-color: #f7f8fe;
    border-bottom: 2px solid #e9ecef !important;
    z-index: 100;
    top: 0px;
}
</style>