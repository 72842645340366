<template>
    <sponsor-list-component
        :routeName="'employee-sponsor-jobs-offer-list'"
    ></sponsor-list-component>
</template>

<script>
import SponsorListComponent from "@/components/Managment/Sponsors/SponsorListComponent.vue";

export default {
    name: 'SponsorList',
    components: { SponsorListComponent }, 
}
</script>