<template>
    <!-- Calendar by Vuetify - EmployeeCalendar -->
    <v-row class="fill-height">
        <v-col>
            <v-sheet height="64">
                <div flat style="overflow:hidden" class="d-flex align-items-center">
                    <!-- btn today -->
                    <v-btn
                        outlined
                        class="mr-2"
                        color="grey darken-2"
                        @click="setToday"
                    >hoy
                    </v-btn>

                    <!-- reload -->
                    <b-button variant="outline-primary" size="sm" @click="reloadData" class="mr-0 py-2">
                        <b-icon icon="arrow-repeat" scale="1.2"></b-icon>
                    </b-button>

                    <!-- btn prev month -->
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                    >
                        <v-icon small>
                        mdi-chevron-left
                        </v-icon>
                    </v-btn>

                    <!-- month - year -->
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>

                    <!-- btn next month -->
                    <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                    >
                        <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>


                    <v-spacer></v-spacer>

                    <v-menu bottom right >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Día</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Semana</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Mes</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 días</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-sheet>

            <v-sheet height="560">
                <v-calendar
                    locale="es"
                    :short-weekdays="false"
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="updateRange"
                    
                    >
                </v-calendar>

                <!-- evento seleccionado -->
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                >
                    <v-card
                        color="grey lighten-4"
                        flat
                        class="card-event-display"
                    >
                        <v-toolbar :color="selectedEvent.color" dark>
                            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="selectedOpen = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <!-- EnglishInterviewSchedule -->
                        <div v-if="selectedEvent.card_type == 'EnglishInterviewSchedule'">
                            <v-card-text >
                                 <b-row>
                                    <b-col class="text-caption text-uppercase text-center
                                        card-english-interview-schedule-agenda mx-2 py-1"
                                        :class="selectedEvent.schedule_status == 'pendiente' ? 'text-magenta' :( selectedEvent.schedule_status == 'disponible' ? 'text-schedule-english-2' : 'text-schedule-english-1')"
                                    >
                                        <span>Agenda </span>
                                        <span>{{ selectedEvent.salesAdvisor_id ? 'Asesor ventas' : 'Asesor cultural' }}</span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Estado</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span   :class="selectedEvent.schedule_status == 'pendiente' ? 'text-magenta' :( selectedEvent.schedule_status == 'disponible' ? 'text-schedule-english-2' : 'text-schedule-english-1') ">
                                            {{ selectedEvent.schedule_status }}
                                        </span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption"
                                    v-if="selectedEvent.details"
                                >
                                    <b-col cols="4" class="text-right">Detalles</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.details }} </span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Postulante</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6" class="card-section-scrollable">
                                        <div class="text-capitalize" style="width:max-content">
                                            {{ selectedEvent.postulant_full_name }} - {{ selectedEvent.postulant_program_name}} - {{ selectedEvent.postulant_season_name }}
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption" align-v="center"
                                    v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                >
                                    <b-col cols="4" class="text-right">Reunión (link)</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <b-button
                                            :href="selectedEvent.link" target="_blanck"
                                            :variant="selectedEvent.schedule_status == 'pendiente' ? 'magenta' : 'schedule-english-2'"
                                            size="sm"
                                        ><b-icon icon="link" variant="white"></b-icon></b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Hora</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                    </b-col>
                                </b-row>
                            </v-card-text>

                            <v-card-actions
                                v-if="selectedEvent.card_type == 'EnglishInterviewSchedule'"
                                style="display:flex;justify-content:center" 
                                >
                                <v-btn text color="secondary" 
                                    :to="{
                                        name: 'postulant-program-interviews-interview-details',
                                            params: { 
                                                'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                                'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                            }
                                        }"
                                >
                                    Detalles
                                </v-btn>
                            </v-card-actions>
                        </div>

                        <!-- contract -->
                        <div 
                            v-if="selectedEvent.card_type == 'Contract'"
                            class="m-0 p-0"
                        >
                            <v-card-text >
                                <span >FECHA MAX.: {{selectedEvent.expiration_date_formated}} </span>
                            </v-card-text>
                        </div>

                        <!-- ContractPayment -->
                        <div 
                            v-if="selectedEvent.card_type == 'ContractPayment'"
                            class="m-0 p-0"
                        >
                            <v-card-text >
                                <span >{{selectedEvent.details}} </span>
                                <br>
                                <span >Fecha límite: {{selectedEvent.start}} </span>
                                <br>
                                <div style="width:75px">
                                    <traffic-light :status="selectedEvent.status"/>
                                </div>
                            </v-card-text>
                        </div>

                        <!-- CapacitationSchedule -->
                        <div
                            v-if="selectedEvent.card_type == 'CapacitationSchedule'"
                            class="m-0 p-0"
                        >
                            <v-card-text>
                                    <b-row>
                                        <b-col class="text-caption text-uppercase text-center text-schedule-capacitation-3
                                            card-agenda mx-2 py-1"
                                        >
                                            <span>Agenda </span>
                                            <span>Asesor cultural</span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption"
                                        v-if="selectedEvent.details"
                                    >
                                        <b-col cols="4" class="text-right">Detalles</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.details }} </span>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption" align-v="center"
                                        v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                    >
                                        <b-col cols="4" class="text-right">Reunión (link)</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                             <b-button
                                                :href="selectedEvent.link" target="_blanck"
                                                :variant="selectedEvent.schedule_status == 'pendiente' ? 'schedule-capacitation-2' : 'schedule-capacitation-2'"
                                                size="sm"
                                            ><b-icon icon="link" variant="white"></b-icon></b-button>
                                        </b-col>
                                    </b-row>

                                    <b-row class="text-caption">
                                        <b-col cols="4" class="text-right">Hora</b-col>
                                        <b-col cols="1" class="text-center">:</b-col>
                                        <b-col cols="6">
                                            <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                        </b-col>
                                    </b-row>
                                </v-card-text>

                            <!-- <v-card-actions style="display:flex;justify-content:center"> -->
                                <!-- <v-btn
                                    text
                                    color="secondary"
                                    v-if="selectedEvent.programSeasonPostulant_id != null && selectedEvent.culturalAdvisor_id != null"
                                    :to="{ 
                                        name: 'employee-postulants-interview-details-CA',
                                        params: { 
                                            'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                            'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                        }
                                    }"
                                >
                                    Detalles
                                </v-btn> -->
                            <!-- </v-card-actions> -->
                        </div>

                        <!-- TalkSchedule -->
                        <div
                            v-if="selectedEvent.card_type == 'TalkSchedule'"
                            class="m-0 p-0"
                        >
                            <v-card-text>
                                <b-row>
                                    <b-col class="text-caption text-uppercase text-center text-schedule-talk-3
                                        card-agenda mx-2 py-1"
                                    >
                                        <span>Agenda </span>
                                        <span>Asesor cultural</span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption"
                                    v-if="selectedEvent.details"
                                >
                                    <b-col cols="4" class="text-right">Detalles</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.details }} </span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption" align-v="center"
                                    v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                >
                                    <b-col cols="4" class="text-right">Reunión (link)</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                            <b-button
                                            :href="selectedEvent.link" target="_blanck"
                                            :variant="selectedEvent.schedule_status == 'pendiente' ? 'schedule-talk-2' : 'schedule-talk-2'"
                                            size="sm"
                                        ><b-icon icon="link" variant="white"></b-icon></b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption"                                >
                                    <b-col cols="4" class="text-right">Hora</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                    </b-col>
                                </b-row>
                            </v-card-text>

                            <!-- <v-card-actions style="display:flex;justify-content:center"> -->
                                <!-- <v-btn
                                    text
                                    color="secondary"
                                    v-if="selectedEvent.programSeasonPostulant_id != null && selectedEvent.culturalAdvisor_id != null"
                                    :to="{ 
                                        name: 'employee-postulants-interview-details-CA',
                                        params: { 
                                            'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                            'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                        }
                                    }"
                                >
                                    Detalles
                                </v-btn> -->
                            <!-- </v-card-actions> -->
                        </div>

                        <!-- SponsorInterview -->
                        <div v-if="selectedEvent.card_type == 'SponsorInterview'">
                            <v-card-text >
                                 <b-row>
                                    <b-col class="text-caption text-uppercase text-center
                                        card-agenda mx-2 py-1"
                                        :class="selectedEvent.schedule_status == 'pendiente' ? 'text-sponsor-interview-2' : 'text-sponsor-interview-1'"
                                    >
                                        <span>{{ selectedEvent.sponsor_name }}</span>
                                    </b-col>
                                </b-row>

                                <!-- <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Estado</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span   :class="selectedEvent.schedule_status == 'pendiente' ? 'text-sponsor-interview-2' : 'text-sponsor-interview-0'">
                                            {{ selectedEvent.schedule_status }}
                                        </span>
                                    </b-col>
                                </b-row> -->

                                <b-row class="text-caption"
                                    v-if="selectedEvent.details"
                                >
                                    <b-col cols="4" class="text-right">Detalles</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.details }} </span>
                                    </b-col>
                                </b-row>

                                <!-- <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Postulante</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6" class="card-section-scrollable">
                                        <div class="text-capitalize" style="width:max-content">
                                            {{ selectedEvent.postulant_full_name }} - {{ selectedEvent.postulant_program_name}} - {{ selectedEvent.postulant_season_name }}
                                        </div>
                                    </b-col>
                                </b-row> -->

                                <b-row class="text-caption" align-v="center"
                                    v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                >
                                    <b-col cols="4" class="text-right">Reunión ({{ selectedEvent.room_type == 'url' ? 'link' : 'cód. skype'}})</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <b-button
                                            v-show="selectedEvent.room_type == 'url'"
                                            :href="selectedEvent.link" target="_blanck"
                                            :variant="selectedEvent.schedule_status == 'pendiente' ? 'sponsor-interview-2' : 'sponsor-interview-1'"
                                            size="sm"
                                        ><b-icon icon="link" variant="white"></b-icon></b-button>

                                        <span v-show="selectedEvent.room_type == 'skype'" class="text-sponsor-interview-3">{{ selectedEvent.link }}</span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption" align-v="center"                                >
                                    <b-col cols="4" class="text-right">Hora</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span  class="text-sponsor-interview-3">{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }}</span>
                                    </b-col>
                                </b-row>
                            </v-card-text>

                            <v-card-actions
                                v-if="selectedEvent.card_type == 'EnglishInterviewSchedule'"
                                style="display:flex;justify-content:center" 
                                >
                                <v-btn text color="secondary" 
                                    :to="{
                                        name: 'postulant-program-interviews-interview-details',
                                            params: { 
                                                'IdProgramSeasonPostulant': selectedEvent.programSeasonPostulant_id,
                                                'IdEnglishInterviewSchedule': selectedEvent.englishInterviewSchedule_id
                                            }
                                        }"
                                >
                                    Detalles
                                </v-btn>
                            </v-card-actions>
                        </div>

                        <!-- EmployerInterview -->
                        <div v-if="selectedEvent.card_type == 'EmployerInterview'">
                            <v-card-text >
                                 <b-row>
                                    <b-col class="text-caption text-uppercase text-center
                                        card-agenda mx-2 py-1"
                                        :class="selectedEvent.schedule_status == 'pendiente' ? 'text-employer-interview-3' : 'text-employer-interview-1'"
                                    >
                                        <span>{{ selectedEvent.job_offer_name }}</span>
                                    </b-col>
                                </b-row>

                                <!-- <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Estado</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span   :class="selectedEvent.schedule_status == 'pendiente' ? 'text-employer-interview-2' : 'text-employer-interview-0'">
                                            {{ selectedEvent.schedule_status }}
                                        </span>
                                    </b-col>
                                </b-row> -->

                                <b-row class="text-caption"
                                    v-if="selectedEvent.details"
                                >
                                    <b-col cols="4" class="text-right">Detalles</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.details }} </span>
                                    </b-col>
                                </b-row>

                                <!-- <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Postulante</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6" class="card-section-scrollable">
                                        <div class="text-capitalize" style="width:max-content">
                                            {{ selectedEvent.postulant_full_name }} - {{ selectedEvent.postulant_program_name}} - {{ selectedEvent.postulant_season_name }}
                                        </div>
                                    </b-col>
                                </b-row> -->

                                <b-row class="text-caption" align-v="center"
                                    v-show="selectedEvent.schedule_status == 'culminado' ? false : true"
                                >
                                    <b-col cols="4" class="text-right">Reunión ({{ selectedEvent.room_type == 'url' ? 'link' : 'cód. skype'}})</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <b-button
                                            v-show="selectedEvent.room_type == 'url'"
                                            :href="selectedEvent.link" target="_blanck"
                                            :variant="selectedEvent.schedule_status == 'pendiente' ? 'employer-interview-2' : 'employer-interview-0'"
                                            size="sm"
                                        ><b-icon icon="link" variant="white"></b-icon></b-button>

                                        <span v-show="selectedEvent.room_type == 'skype'" class="text-employer-interview-3">{{ selectedEvent.link }}</span>
                                    </b-col>
                                </b-row>

                                <b-row class="text-caption">
                                    <b-col cols="4" class="text-right">Hora</b-col>
                                    <b-col cols="1" class="text-center">:</b-col>
                                    <b-col cols="6">
                                        <span>{{ selectedEvent.start_time }} - {{ selectedEvent.end_time }} </span>
                                    </b-col>
                                </b-row>
                            </v-card-text>
                        </div>

                    </v-card>
                </v-menu>
            </v-sheet>
        </v-col>

        <!-- <b-button @click="modalContractPaymentClick"> sss</b-button> -->
        <popup></popup>
    </v-row>
</template>

<script>
import { getPostulantCalendar } from '@/api/Postulant/PostulantCalendar'
import TrafficLight from '@/layout/Icons/TrafficLight.vue'
import Popup from '@/components/Postulant/popup.vue'

export default {
    name: 'PostulantCalendar',
    components: { 
        TrafficLight,
        Popup
    },
    data: () => ({
        color: '',
        focus: new Date().toISOString().substring(0,10),
        type: 'month',
        typeToLabel: {
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            '4day': '4 días',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
    }),

    computed: {
        userPostulantData () {
            return this.$store.getters['user/getUserRoleData']('postulante')
        }
    },

    mounted () {
        this.$refs.calendar.checkChange()
    },
    methods: {
        //
        reloadData() {
            this.getPostulantCalendarEvents()
        },
        /**
         * OWN CALENDAR METHODS
         */
        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = new Date().toISOString().substring(0,10)
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        /**
         * 
         */
        updateRange ({ start, end }) {
            console.log('start:', start, '-- end: ', end);
            this.getPostulantCalendarEvents()
        },

        // getPostulantCalendar
        getPostulantCalendarEvents () {
            //falta loading

            getPostulantCalendar(this.userPostulantData.attributes.idToManage)
                .then(({data}) => {
                    this.events = data.data.map(el => {
                        if (el.type == 'EnglishInterviewSchedule') {
                            return {
                                card_type                       : el.type,
                                englishInterviewSchedule_id     : el.attributes.id,
                                //
                                salesAdvisor_id                 : el.attributes.salesAdvisor_id,
                                culturalAdvisor_id              : el.attributes.culturalAdvisor_id,
                                //
                                start_time                      : el.attributes.start_time,
                                end_time                      : el.attributes.end_time,
                                programSeasonPostulant_id       : el.attributes.programSeasonPostulant_id,
                                postulant_full_name             : el.attributes.postulant_full_name,
                                postulant_program_name          : el.attributes.postulant_program_name,
                                postulant_season_name           : el.attributes.postulant_season_name,
                                //
                                schedule_status                 : el.attributes.schedule_status,
                                // -------
                                name                            : el.attributes.subject,
                                start                           : el.attributes.start,
                                end                             : el.attributes.end,
                                color                           : el.attributes.schedule_status == 'culminado' ? '#DFEEEA' : '#5E8B7E',
                                details                         : el.attributes.schedule_description,
                                link                            : el.attributes.link,
                                timed                           : true,
                            }
                        } else if (el.type == 'Contract') {
                            return {
                                card_type:                  el.type,
                                //
                                contract_id:                el.attributes.id,
                                culturalAdvisor_id:         el.attributes.culturalAdvisor_id,
                                culturalAdvisor_full_name:  el.attributes.culturalAdvisor_full_name,
                                generated_file:             el.attributes.generated_file,
                                programSeasonPostulant_id:  el.attributes.programSeasonPostulant_id,
                                expiration_date_formated:   el.attributes.expiration_date_formated,
                                status:                     el.attributes.status,
                                // -------
                                name:               'Contrato',
                                start:              el.attributes.expiration_date,
                                color:              el.attributes.status == 'valido' ? '#1572A1' : '#9AD0EC',
                                details:            el.attributes.description,
                                timed:              true,
                            }
                        } else if (el.type == 'ContractPayment') {
                            return {
                                card_type:          el.type,
                                //
                                contractPayment_id: el.attributes.id,
                                contract_id:        el.attributes.contract_id,
                                amount_format:      el.attributes.amount_format,
                                status:             el.attributes.status,
                                // -------
                                name:               el.attributes.payment_concept,
                                start:              el.attributes.expiration_at,
                                color:              '#DB6B97',
                                amount:             el.attributes.amount,
                                details:            el.attributes.description,
                                timed:              true,
                            }
                        } else if (el.type == 'CapacitationSchedule') {
                            return {
                                card_type                           : el.type,
                                capacitationsSchedule_id            : el.attributes.id,
                                //
                                // salesAdvisor_id                     : el.attributes.salesAdvisor_id,
                                culturalAdvisor_id                  : el.attributes.culturalAdvisor_id,
                                //
                                capacitationScheduleParticipants    : el.attributes.capacitationScheduleParticipants,
                                //
                                start_date                          : el.attributes.start_date,
                                start_time                          : el.attributes.start_time,
                                end_date                            : el.attributes.end_date,
                                end_time                            : el.attributes.end_time,
                                schedule_description                : el.attributes.schedule_description,
                                schedule_status                     : el.attributes.schedule_status,
                                // -------
                                name                                : el.attributes.subject,
                                start                               : el.attributes.start,
                                end                                 : el.attributes.end,
                                // color                               : el.attributes.schedule_status == 'culminado' ? '#C6CBEF' : (el.attributes.schedule_status == 'pendiente' ? '#EF4A81' : '#8186D5'),
                                color                               : el.attributes.schedule_status == 'culminado' ? '#C6CBEF' :  '#8186D5',
                                details                             : el.attributes.schedule_description,
                                link                                : el.attributes.link,
                                timed                               : true,
                            }
                        } else if (el.type == 'TalkSchedule') {
                            return {
                                card_type                           : el.type,
                                talkSchedule_id                     : el.attributes.id,
                                //
                                // salesAdvisor_id                     : el.attributes.salesAdvisor_id,
                                culturalAdvisor_id                  : el.attributes.culturalAdvisor_id,
                                //
                                talkScheduleParticipants            : el.attributes.talkScheduleParticipants,
                                //
                                start_date                          : el.attributes.start_date,
                                start_time                          : el.attributes.start_time,
                                end_date                            : el.attributes.end_date,
                                end_time                            : el.attributes.end_time,
                                schedule_description                : el.attributes.schedule_description,
                                schedule_status                     : el.attributes.schedule_status,
                                // -------
                                name                                : el.attributes.subject,
                                start                               : el.attributes.start,
                                end                                 : el.attributes.end,
                                color                               : el.attributes.schedule_status == 'culminado' ? '#FFD07F' : '#FF8243',
                                details                             : el.attributes.schedule_description,
                                link                                : el.attributes.link,
                                timed                               : true,
                            }
                        } else if (el.type == 'SponsorInterview') {
                            return {
                                card_type                       : el.type,
                                sponsorInterview_id             : el.attributes.id,
                                //
                                // programSeasonPostulant_id       : el.attributes.programSeasonPostulant_id,
                                // postulant_full_name             : el.attributes.postulant_full_name,
                                // postulant_program_name          : el.attributes.postulant_program_name,
                                // postulant_season_name           : el.attributes.postulant_season_name,
                                //
                                start_time                      : el.attributes.start_time,
                                end_time                        : el.attributes.end_time,
                                sponsor_name                    : el.attributes.sponsor_name,
                                schedule_status                 : el.attributes.schedule_status,
                                room_type                       : el.attributes.room_type,
                                // -------
                                name                            : 'Sponsor-Entrevista', //el.attributes.subject,
                                start                           : el.attributes.start,
                                end                             : el.attributes.end,
                                color                           : el.attributes.schedule_status == 'culminado' ? '#B3E8E5' : '#3BACB6',
                                details                         : el.attributes.schedule_description,
                                link                            : el.attributes.link,
                                timed                           : true,
                            }
                        } else if (el.type == 'EmployerInterview') {
                            return {
                                card_type                       : el.type,
                                sponsorInterview_id             : el.attributes.id,
                                //
                                // programSeasonPostulant_id       : el.attributes.programSeasonPostulant_id,
                                // postulant_full_name             : el.attributes.postulant_full_name,
                                // postulant_program_name          : el.attributes.postulant_program_name,
                                // postulant_season_name           : el.attributes.postulant_season_name,
                                //
                                start_time                      : el.attributes.start_time,
                                end_time                        : el.attributes.end_time,
                                job_offer_name                  : el.attributes.job_offer_name,
                                schedule_status                 : el.attributes.schedule_status,
                                room_type                       : el.attributes.room_type,
                                // -------
                                name                            : 'Empleador-Entrevista', //el.attributes.subject,
                                start                           : el.attributes.start,
                                end                             : el.attributes.end,
                                color                           : el.attributes.schedule_status == 'culminado' ? '#ABA9E9' : '#64638F',
                                details                         : el.attributes.schedule_description,
                                link                            : el.attributes.link,
                                timed                           : true,
                            }
                        }



                    })
                })
                .catch(err => {
                    console.log((err));
                    console.log((err.response));
                })
                .finally(() => {
                    console.log('get postulant calendar data end');
                })
        }
    },
  }
</script>

<style scoped>
.card-agenda {
    border: 1.5px solid;
    border-radius: 5px;
}
@media (max-width: 575px) {
    .card-event-display {
        width: 350px;
    }
}
@media (min-width: 576px) {
    .card-event-display {
        min-width: 350px;
    }
}
/** scrollable */
.card-section-scrollable {
    overflow-x: auto;
}
.card-section-scrollable::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    display: none;
}
@media (max-width: 575px) {
    .card-section-scrollable::-webkit-scrollbar {
        width: 2.5px;
        height: 2.5px;
        display: block;
    }
}
.card-section-scrollable::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: rgba(94, 114, 228, 0.15);
}
.card-section-scrollable::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: rgba(94, 114, 228, 0.25);
}
.card-section-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: rgba(94, 114, 228, 0.35);
}
/** end */
</style>