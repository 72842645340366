<template>
  <abandonments-list-component :ui="'cultural_advisor'"></abandonments-list-component>    
</template>

<script>
import AbandonmentsListComponent from '@/components/Admin-Employee/Abandonment/AbandonmentsListComponent.vue'
export default {
  components: { AbandonmentsListComponent },
    data: () => ({})
}
</script>
