<template>
    <program-documents />
</template>

<script>
import ProgramDocuments from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramDocuments.vue'

export default {
    name: "InternshipDocuments",
    components: { ProgramDocuments },
}
</script>