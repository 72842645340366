<template>
    <b-row>
        <b-col cols="12" class="mb-4">
            <b-card class="shadow" no-body>
                <b-card-header header-border-variant="white">
                    <b-row>
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Lista de programas</h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <b-card no-body v-show="!loadingTableData">
                    <div class="table-responsive" >
                        <base-table class="table align-items-center table-flush"
                            thead-classes="thead-light"
                            tbody-classes="list"
                            :data="tableData"
                        >
                            <template slot="columns">
                                <th>#</th>
                                <th>programa</th>
                                <th>temporada</th>
                                <th>Responsable</th>
                                <th>estado</th>
                                <th class="text-center">detalles</th>
                            </template>

                            <template slot-scope="{row, index}">
                                <td>
                                    <!-- numeración descendente -->
                                    {{ tableMeta.total - ((tableMeta.per_page * (tableMeta.current_page - 1)) + (index + 1)) + 1 }}
                                </td>

                                <td>{{ row.attributes.program}}</td>
                                <td>{{ row.attributes.season}}</td>

                                <td>
                                    <div v-if="row.attributes.postulant_salesAdvisor_id">
                                        <span class="text-primary">ventas :</span> {{ row.attributes.postulant_salesAdvisor_full_name }}
                                    </div>

                                    <div>
                                        <span class="text-primary">cultural :</span> {{ row.attributes.culturalAdvisor_full_name }}
                                    </div>
                                </td>

                                <td>
                                    <badge class="badge-dot mr-4" :type="row.attributes.status == 'activo' ? 'success' : (row.attributes.status == 'finalizado' ? 'danger' : 'warning')">
                                        <i :class="row.attributes.status == 'activo' ? 'bg-green' : (row.attributes.status == 'finalizado' ? 'bg-danger' : 'bg-light')"></i>
                                        <span class="status">{{ row.attributes.status }}</span>
                                    </badge>
                                </td>

                                <td class="text-center">
                                    <b-btn 
                                        v-show="row.attributes.status == 'activo'"
                                        size="sm"
                                        variant="outline-primary"
                                        :to="{
                                            name: 'participant-program-details',
                                            params: { 
                                                'IdProgramSeasonParticipant': row.id,       // IdProgramSeasonParticipant
                                                'IdProgramSeasonPostulant': row.attributes.programSeasonPostulant_id,       // IdProgramSeasonPostulant
                                                'IdProgramSeason': row.attributes.programSeason_id,    // IdProgramSeason
                                            }
                                        }"
                                    >
                                        <b-icon icon="three-dots" ></b-icon>
                                    </b-btn>
                                </td>
                            </template>
                        </base-table>
                    </div>
                </b-card>

                <!-- pagination -->
                <b-card-footer v-show="!loadingTableData" footer-border-variant="white">
                    <base-pagination
                        align="center"
                        :pageCount="tableMeta.last_page" 
                        :perPage="tableMeta.per_page"
                        :value="tableMeta.current_page"
                        @input="paginationCLiked"
                    />
                </b-card-footer>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
// participant.programSeasonParticipant
import { getProgramSeasonParticipants } from '@/api/Participant/ParticipantProgramSeasonParticipant'

export default {
    name: 'ProgramsList',
    data: () => ({
        // ProgramSeasonPostulants as programs - by participant
        tableData: [],
        tableMeta: {},
        loadingTableData: false,
    }),
    computed: {
        participantUserRole () {
            return this.$store.getters['user/getUserRoleByRolname']('participante')
        }
    },
     created () {
        this.getProgramsData()
    },
    methods: {
        getProgramsData (pPage = 1) {
            this.loadingTableData = true

            let IdParticipant = this.participantUserRole.attributes.idToManage

            getProgramSeasonParticipants(pPage, IdParticipant)
                .then(({data}) => {
                    console.log('getProgramSeasonParticipants data', data);
                    this.tableData = data.data
                    this.tableMeta = data.meta
                })
                .catch(err => {
                    console.log('request getProgramSeasonParticipants error', err.response)
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('request getProgramSeasonParticipants end');
                })
        },

        paginationCLiked (e) {
            this.getProgramsData(e)
        },

    }
}
</script>