<template>
    <b-row class="mb-4">
        <b-col>
            <b-card class="shadow" no-body>
                <b-card-header class="border-0">
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Capacitaciones Agendadas
                            </h3>
                        </b-col>

                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" @click="reloadData">
                                <b-icon icon="arrow-repeat"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- loadingTableData -->
                <div style="height:300px;" v-show="loadingTableData">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <!-- table -->
                <div class="table-responsive" v-show="!loadingTableData">
                    <base-table class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="tableData"
                    >
                        <template slot="columns">
                            <th>#</th>
                            <th>link</th>
                            <th>Materiales</th>
                            <th>Capacitación</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Capacitador</th>
                            <th>Estado</th>
                            <th></th>
                        </template>

                        <template slot-scope="{row, index}">
                             <td>{{ index + 1 }}</td>

                            <td>
                                <b-btn
                                    :href="row.attributes.link" target="_blanck"
                                    size="sm"
                                    variant="primary"
                                ><b-icon icon="link" variant="white"></b-icon>
                                </b-btn>
                            </td>

                            <td>
                                <b-btn
                                    @click="modalShowMaterialsClick(row.attributes.materials)"
                                    size="sm"
                                    variant="cielo"
                                ><b-icon icon="files" variant="white"></b-icon>
                                </b-btn>
                            </td>

                            <td>{{ row.attributes.subject }}</td>
                            <td>{{ row.attributes.start_date }}</td>
                            <td>{{ row.attributes.start_time }} a {{ row.attributes.end_time }}</td>

                            <td>
                                {{ row.attributes.culturalAdvisor_full_name }}
                            </td>

                            <td :class="row.attributes.schedule_status == 'pendiente' ? 'text-yellow' : 'text-green'">
                                {{ row.attributes.schedule_status }}</td>
                            <td ></td>
                        </template>
                    </base-table>
                </div>
            </b-card>
        </b-col>

        <!--
            modal show material
        -->
        <b-modal
            id="modal-show-materials"
            centered
            hide-footer
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
        >
            <!-- modal header -->
            <template #modal-header="">
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-h="between" align-v="center">
                        <b-col cols="auto"><h3 class="text-primary mb-0 text-button">MATERIALES</h3></b-col>
                        <b-col cols="auto">
                            <b-button size="sm" variant="magenta" @click="modalShowMaterialsClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <!-- modal content -->
            <template #default>
                <b-container>
                    <b-card no-body>
                        <!-- table -->
                        <div class="table-responsive" v-show="!loadingTableData">
                            <base-table class="table align-items-center table-flush"
                                thead-classes="thead-light"
                                tbody-classes="list"
                                :data="moreMaterials"
                            >
                                <template slot="columns">
                                    <th>#</th>
                                    <th>Material</th>
                                    <th>Nombre</th>
                                    <th>link_compartido</th>
                                    <th>Descripción</th>
                                    <th></th>
                                </template>

                                <template slot-scope="{row, index}">
                                    <td>{{ index + 1 }}</td>

                                    <td>
                                        <b-btn
                                            :href="row.attributes.file" target="_blanck"
                                            size="sm"
                                            :variant="row.attributes.file ? 'cielo' : 'light'"
                                        ><b-icon icon="file-text" variant="white"></b-icon>
                                        </b-btn>
                                    </td>

                                    <td>{{ row.attributes.name }}</td>

                                    <td>
                                        <b-btn
                                            :href="row.attributes.shared_link" target="_blanck"
                                            size="sm"
                                            :variant="row.attributes.shared_link ? 'primary' : 'light'"
                                        ><b-icon icon="link" variant="white"></b-icon>
                                        </b-btn>
                                    </td>

                                    <td>{{ row.attributes.description }}</td>

                                    <td>
                                    </td>
                                </template>
                            </base-table>
                        </div>
                    </b-card>
                </b-container>
            </template>
        </b-modal>
    </b-row>
</template>

<script>
// programSeasonParticipant.capacitationSchedule
import { getCapacitationSchedules } from '@/api/ProgramSeasonParticipant/CapacitationSchedules';

export default {
    name: "CapacitationsSchedules",
    data: () => ({
        // table 
        tableData: [],
        loadingTableData: false,
        //
        moreMaterials: [],
        documentEditLoading: false,
    }),
    created() {
        this.getParticipantCapacitationsSchedulesData()
    },
    methods: {
        getParticipantCapacitationsSchedulesData () {
            this.loadingTableData = true

            let IdProgramSeasonParticipant = this.$route.params.IdProgramSeasonParticipant  // ok

            getCapacitationSchedules(IdProgramSeasonParticipant)
                .then(({ data }) => {
                    this.tableData = data.data
                })
                .catch( err => {
                    console.log('getCapacitationSchedule Error:', err.response);
                })
                .finally(() => {
                    this.loadingTableData = false
                    console.log('getCapacitationSchedule request end');
                })
        },

        modalShowMaterialsClick (pMaterials) {
            this.moreMaterials = pMaterials.map(el => el)
            this.$bvModal.show('modal-show-materials')
        },
        modalShowMaterialsClose () {
            this.$bvModal.hide('modal-show-materials')
            this.clearInputsAndStates()
        },

        clearInputsAndStates () {
            this.moreMaterials = []
        },
        reloadData () {
            this.getParticipantCapacitationsSchedulesData()
        }
    }
}
</script>