<template>
      <div>
        <base-header class="header pb-8 pt-16 pt-lg-16"
            style="min-height:370px;background-image:url(/img/theme/profile-cover.jpg);background-size:cover;background-position:10%;"
        >
            <!-- Mask -->
            <span class="mask bg-gradient-violeta opacity-7"></span>

            <!-- video -->
            <!-- <b-row align-v="center">
                    <b-col class="text-right">
                        <b-button size="sm"  variant="magenta" @click="showVideo">
                            <b-icon icon="collection-play-fill" scale="1.2"></b-icon>
                        </b-button>
                    </b-col>
                </b-row> -->
        </base-header>

        <div class="container-fluid mt--15 mt-md--13 mt-lg--13">
            <notification-component
                class="d-lg-none mb-5"
                :order="'1'"
                :canResize="'no'"
                :notificationHeight="'250px'"
                :amount="3">
            </notification-component>

            <b-row>
                <b-col cols="12" lg="9" class="mb-5 mb-xl-0">
                    <b-card class="shadow" id="postulant_calendar">
                        <postulant-calendar />
                    </b-card>
                </b-col>

                <b-col lg="3" class="d-none d-lg-block">
                    <notification-component
                        :order="'2'"
                        :canResize="'si'"
                        :amount="9">
                    </notification-component>
                </b-col>
            </b-row>
        </div>

        <!-- modal -->
        <b-modal
            id="modal-video-postulante"
            hide-footer hide-header-close
            size="xl"
            centered
            title-class="text-primary text-uppercase"
            header-class="pb-0"
        >
            <template #modal-header>
                <b-container fluid style="border-bottom:1px solid rgba(0, 0, 0, 0.075)" class="pt-1 pb-3">
                    <b-row align-v="center">
                        <b-col class="text-right">
                            <b-button size="sm" variant="magenta" @click="modalVideoPostulanteClose">
                                <b-icon icon="x" scale="1.5"/>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </template>

            <div class="video-modal">
                <pulse-loader
                    :loading="loading"
                    :color="'#5e72e4'"
                    :margin="'14px'"
                    :size="18"
                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"
                ></pulse-loader>

                <iframe width="100%" height="100%" 
                    src="https://www.youtube-nocookie.com/embed/vRBk8TdRBzQ" 
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    v-show="!loading"
                ></iframe>
            </div>


        </b-modal>
    </div>
</template>

<script>
import PostulantCalendar from '@/components/Calendars/PostulantCalendar.vue'
import NotificationComponent from '@/components/Postulant-Participant/NotificationComponent/NotificationComponent.vue'

export default {
    name: 'DashboardComponent',
    components : {
        PostulantCalendar,
        NotificationComponent
    },
    data: () => ({ loading: false }),
    methods: {
        showVideo () {
            this.loading = true
            this.$bvModal.show('modal-video-postulante')
            setTimeout(() => {
                this.loading = false
            }, 1200);
        },
        modalVideoPostulanteClose () {
            this.$bvModal.hide('modal-video-postulante')

        }
}
}
</script>

<style scoped>
/* video modal */
@media (min-width: 400px) {
.video-modal {
    height: 250px;
  }
}
@media (min-width: 576px) {
.video-modal {
    height: 300px;
  }
}
@media (min-width: 992px) {
.video-modal {
    height: 500px;
  }
}
@media (min-width: 1200px) {
.video-modal {
    height: 600px;
  }
}
</style>