<template>
    <b-row >
        <!-- postulant details -->
        <b-col cols="12" lg="6" class="mb-4">
            <b-card class="shadow" >
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles del Postulante
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonPostulant -->
                <div style="height:300px;" v-show="programSeasonPostulantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>
                
                <!-- programSeasonPostulant details -->
                <div v-show="!programSeasonPostulantDataLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Nombres</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.user_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Apellidos</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.user_last_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Documento</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.user_document_number }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Celular</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.user_phone_number }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Correo</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.user_email }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>

        <b-col cols="12" lg="6">
            <!-- programSeasonPostulant details -->
            <b-card class="shadow" >
                <!-- card header -->
                <b-card-header>
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                Detalles del Programa
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header>

                <!-- laoding programSeasonPostulant -->
                <div style="height:300px;" v-show="programSeasonPostulantDataLoading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,50%)"/>
                </div>

                <div v-show="!programSeasonPostulantDataLoading" class="mt-5">
                    <b-list-group>
                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Programa</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.program }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Temporada</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.season }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item
                            v-show="programSeasonPostulantData.attributes.salesAdvisor_id"
                        >
                            <b-row>
                                <b-col cols="4">Asesor de Ventas</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.salesAdvisor_full_name }}</b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Asesor Cultural</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>
                                    <div v-show="programSeasonPostulantData.attributes.culturalAdvisorAssigned_id ? true : false">
                                        {{ programSeasonPostulantData.attributes.culturalAdvisorAssigned_full_name }}
                                    </div>

                                    <div v-show="programSeasonPostulantData.attributes.culturalAdvisorAssigned_id ? false : true">
                                        <b-button
                                            size="sm"
                                            variant="outline-light"
                                            disabled
                                        >
                                            pendiente
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-list-group-item>

                        <b-list-group-item>
                            <b-row>
                                <b-col cols="4">Estado</b-col>
                                <b-col cols="1">:</b-col>
                                <b-col>{{ programSeasonPostulantData.attributes.status }}</b-col>
                            </b-row>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { getProgramSeasonPostulant } from '@/api/ProgramSeasonPostulant/ProgramSeasonPostulant';
//import { PulseLoader } from '@saeris/vue-spinners'

export default {
    name: "ProgramDetails",
    //components: { PulseLoader },
    data: () => ({
        programSeasonPostulantData: {
            attributes: {}
        },
        programSeasonPostulantDataLoading: false,
    }),
    created() {
        this.getData()
    },
    methods: {
        getData () {
            this.programSeasonPostulantDataLoading = true

            let IdProgramSeasonPostulant = this.$route.params.IdProgramSeasonPostulant // ok

            getProgramSeasonPostulant(IdProgramSeasonPostulant)
                .then(({ data }) => {
                    this.programSeasonPostulantData = data.data
                })
                .catch( err => {
                    console.log('getProgramSeasonPostulant Error:', err.response)
                })
                .finally(() => {
                    this.programSeasonPostulantDataLoading = false
                    console.log('getProgramSeasonPostulant request end');
                })
        },

    }
}
</script>